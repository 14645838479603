@import '../bootstrap';

$color-1: #fdd128;
$color-2: #244199;

/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    & * {
        outline: none;
    }
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
    padding: 18px;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/* Slider */
.slick-prev,
.slick-next {
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 52px;
    height: 52px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 50%;
    z-index: 1;
}
@media (max-width: 767px) {
    .slick-prev,
    .slick-next {
        width: 35px;
        height: 35px;
    }
}
.slick-next:before,
.slick-prev:before {
    content: "";
    border: solid #646464;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 10px;
    @media (max-width: 767px) {
        padding: 6px;
    }
}
.slick-prev:before {
    transform: rotate(135deg);
    margin-left: 8px;
}
.slick-next:before {
    transform: rotate(-45deg);
    margin-right: 8px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
    border-color: #006DB7;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev {
    left: 0;
}
.slick-next {
    right: 0;
}
/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 8px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 14px;
    height: 14px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-size: 34px;
    line-height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 13px;
    content: "\2022";
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
    border: solid 1px $color-2;
    border-radius: 50%;
    background-color: transparent;
}
.slick-dots li.slick-active button:before {
    border-color: $color-2;
    background-color: $color-2;
}


@font-face {
    font-family: "NotoSansArmenian";
    src: url("../../fonts/main-site/NotoSansArmenian-Regular.woff2") format("woff2");
    src: url("../../fonts/main-site/NotoSansArmenian-Regular.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: "NotoSansArmenianBold";
    src: url("../../fonts/main-site/NotoSansArmenian-Bold.woff2") format("woff2");
    src: url("../../fonts/main-site/NotoSansArmenian-Bold.ttf") format("truetype");
    font-display: swap;
    font-weight: 100;
}
@font-face {
    font-family: "Segoe Print";
    src: url("../../fonts/main-site/segoeprint.ttf") format("truetype");
    font-display: swap;
}
@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: opacity .15s linear;
    }
    .collapsing {
        transition: all .3s ease;
    }
}

body {
    min-height: 100vh;
    color: #212529;
    font-family: "NotoSansArmenian", "Poppins", "lucida grande", tahoma, arial, sans-serif;
    opacity: 1!important;
    transition: opacity .3s;
}
.btn {
    padding: 0.65rem 0.9rem;
    border-radius: 0.45rem;
}

img {
    height: auto;
}

.lazyload {
    opacity: 0;

    &.loaded {
        opacity: 1;
        transition: opacity .3s;
    }
}

$base-margin: 20px;
$blue: #345F90;
$tab-height: 35px;
$tab-border-radius: 35px;



.arrow:not(.tooltip .arrow) {
    width: 7px;
    height: 7px;
    transform: rotate(133deg);
    border-right: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    transition: all .3s;
}

.btn-yellow {
    background-color: $color-1;
    color: black;
    transition: all .3s;

    &:hover {
        border-color: $color-1;
        color: black;
        background-color: #ffffff;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgb(255 210 38 / 31%);
    }
}
.btn-blue {
    background-color: $color-2;
    color: #ffffff;

    &:hover {
        border-color: $color-2;
        background-color: #fff;
    }
}
.no-wrap{
    white-space: nowrap;
}
.my-navbar {
    padding: 0;
    background-color: #ffffff;
    box-shadow: 0 3px 6px #00000014;
    line-height: 1.5;
    color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;

    @media (max-width: 1199px) {

        .navbar-collapse {
            position: fixed;
            right: 0;
            top: 50px;
            width: 280px;
            box-shadow: 0 3px 6px #0000002e;
            max-height: calc(100vh - 50px);
            overflow: hidden;
            overflow-y: auto;
            border-radius: 0 0 0 6px;
            background-color: inherit;

            .navbar-nav {
                padding: 24px 24px 30px;
                justify-content: flex-start;
                align-items: stretch!important;
                //background-color: #ffffff;
            }
        }
    }

    .navbar-nav{
        flex-grow: 1;
        justify-content: flex-end;
    }
    .nav-link {
        color: inherit;
        margin: 0 10px;
        padding: 7px 0 !important;
        font-size: 16px;
        transition: color .35s;

        &:hover,
        &.active {
            opacity: .7;
        }

        &:lang(hy){

            @media (min-width: 1200px) and (max-width: 1500px){
                font-size: 15px;
                margin: 0 6px;
            }
        }
        @media (max-width: 1199px) {
            margin: 2px 0;
            font-size: 18px;

            &:lang(hy){
                font-size: 16px;
            }
        }
    }

    .nav-link-notification {
        border: none;
        position: relative;
        margin: 0 0 0 21px;
        padding-right: 5px !important;

        span {
            position: absolute;
            background-color: $color-1;
            font-size: 9px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            color: #505050;
            padding: 1px;
            text-align: center;
            line-height: 1;
            right: -2px;
            top: -2px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;

        &:focus {
            outline: none;
        }
        &:after{
            display: none;
        }
        &[aria-expanded="true"]{

            .menu-arrow:after{
                transform: rotate(-45deg);
            }
            .menu-arrow:before{
                transform: rotate(45deg);
            }
        }
        .menu-arrow{
            display: inline-flex;
            width: 14px;
            height: 14px;
            align-items: center;
            justify-content: center;
            transform-origin: center;
            position: relative;
            margin-left: 6px;
        }
        .menu-arrow:after, .menu-arrow:before {
            content: "";
            display: inline-block;
            height: 1.7px;
            left: 0;
            position: absolute;
            top: 7px;
            width: 8px;
            transform: rotate(45deg);
            background-color: currentColor;
            transition: transform .3s;
        }
        .menu-arrow:before{
            transform: rotate(-45deg);
            left: 5px;
        }

        img {
            width: 32px;
        }
    }

    .dropdown-item:active {
        background-color: #bdd531;
        color: #000000;
    }

    .dropdown-menu {
        position: absolute;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17254901960784313);
        border: none;
        border-radius: 2px;
        margin-top: 6px;
        padding: 10px 0 10px;

        @media (max-width: 1199px){
            padding: 0 10px 10px 16px;
            position: static;
            margin-top: 0;
            margin-bottom: 15px;
            border-radius: 0;
            border-bottom: solid 1px #cacaca;
            box-shadow: none;
        }
        .dropdown-item {
            padding: 0.25rem 1.1rem;

            &:hover {
                background-color: transparent;
                color: $color-2;
                opacity: 1;
                transition: color .3s;
            }

            b {
                width: 21px;
                display: inline-block;

                svg {
                    width: 15px;
                }
            }
        }

        .nav-link{
            color: black;

            @media (max-width: 1199px){
                font-size: 16px;
                margin: 2px 0;
            }
            &:hover{
                color: $color-2;
                opacity: 1;
            }
        }
    }
}
.text-red {
    color: red;
}
.text-blue {
    color: $color-2;
}
.text-yellow {
    color: $color-1;
}
.my-navbar-container {
    padding: 0 24px;
    max-width: 1248px;
    min-height: 70px;
    background-color: inherit;
    transition: min-height 0.3s;

    @media (min-width: 1500px) {
        max-width: 1368px;
    }
    @media (max-width: 1199px) {
        flex-wrap: wrap;
        min-height: 50px;
    }

    .navbar-brand {
        padding: 7px 0;
        margin-right: 10px;

        @media (max-width: 1199px) {
            padding: 5px 0;
        }
        img {
            transition: all .3s;
            width: 120px;

            &:last-child{
                display: block;
            }
            @media (max-width: 1199px) {
                max-width: 130px;
                max-height: 40px;
            }
        }
    }
}


.nav-link-login {
    background-color: $color-1;
    padding: 7px 20px;
    color: #020202;
    letter-spacing: 0.4px;
    margin-left: 15px;
    border: 1px solid transparent;
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
        color: #292929;
        background-color: #ffffff;
        border-color: $color-1;
        transition: all .3s;
    }
    &:lang(hy){
        letter-spacing: 0;

        @media (min-width: 1200px) and (max-width: 1299px){
            font-size: 15px;
        }
    }

    @media (max-width: 1199px) {
        margin-left: 0;
        margin-top: 15px;
        padding: 8px 16px;
        font-size: 16px;

        &:lang(hy){
            font-size: 15px;
            padding: 7px 12px;
        }
        &:lang(ru){
            font-size: 13px;
            padding: 7px 12px;
        }
    }
}
#nav-icon3 {
    width: 30px;
    height: 18px;
    position: relative;
    margin: 0 auto;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: inline-block
}
#nav-icon3,
#nav-icon3 span {
    transform: rotate(0deg)
}
#nav-icon3 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: currentColor;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out
}
#nav-icon3 span:first-child {
    top: 0
}
#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
    top: 8px
}
#nav-icon3 span:nth-child(4) {
    top: 16px
}
.navbar-toggler{
    position: relative;
    z-index: 1;
    color: inherit;
}
.navbar-toggler[aria-expanded=true] #nav-icon3.open span:first-child {
    top: 18px;
    width: 0;
    left: 50%
}
.navbar-toggler[aria-expanded=true] #nav-icon3.open span:nth-child(2) {
    transform: rotate(45deg)
}
.navbar-toggler[aria-expanded=true] #nav-icon3.open span:nth-child(3) {
    transform: rotate(-45deg)
}
.navbar-toggler[aria-expanded=true] #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%
}
.navbar-toggler:focus {
    outline: none
}
.nav-scroll {

    @media (min-width: 1200px) {

        .my-navbar-container {
            min-height: 55px;
            transition: all .3s;
        }
        .my-navbar-container .navbar-brand {
            padding: 10px 0;
            transition: all .3s;

            img {
                width: 100px;
                transition: all .3s;
            }
        }
    }
}

.features-collapse{
    z-index: 9;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    transition: all .3s;

    @media (max-width: 1199px) {
        position: relative;
        top: 0;
    }
}
.features-menu-section{
    background: #ffffff;
    box-shadow: inset 0 2px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 16%);
    padding: 40px 30px 50px;

    @media (max-width: 1199px){
        padding: 8px 0 16px 16px;
        box-shadow: none;
    }
}
.features-menu-section-container{
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 8px 24px;

    @media (max-width: 1199px){
        flex-wrap: wrap;
        gap: 2px 0;
    }
}
.features-link-item{
    width: calc(33.33% - 16px);

    @media (max-width: 1199px){
        width: 100%;
    }
    a{
        color: #000000;
        display: inline-flex;
        align-items: center;
        padding: 8px 0;

        @media (max-width: 1199px){
            font-size: 15px;
        }
        svg{
            margin-right: 20px;

            @media (max-width: 1199px){
                margin-right: 12px;
            }
        }
        &:hover, &.active{
            text-decoration: none;
            color: #244199;
            text-shadow: 0 0 #244199;

            svg{

                path{
                    fill: #244199;
                }
            }
        }
    }
}


.my-footer {
    background-color: #111F4A;
    color: #ffffff;
}
.footer-container {
    padding: 35px 20px 45px;
    max-width: 1116px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: flex-start;

    &:lang(hy){
        max-width: 1180px;

        @media (max-width: 767px){
            max-width: 380px;
        }
    }
    @media (max-width: 991px) {
        padding: 39px 10px 46px;
    }
    @media (max-width: 767px){
        padding: 20px 0 40px;
        justify-content: space-between;
        max-width: 380px;
    }
    h6 {
        letter-spacing: 0.96px;
    }
}
.footer-links {
    margin-top: 30px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding: 0 20px;
    min-width: 20%;

    @media (max-width: 767px){
        margin-top: 20px;
        margin-bottom: 5px;
    }

    li {

        span{
            font-size: 18px;
            margin-bottom: 20px;
            font-weight: 600;
            display: inline-block;
            letter-spacing: .5px;

            @media (max-width: 767px){
                margin-bottom: 15px;
            }
        }
    }

    a {
        color: #E3E3E3;
        white-space: nowrap;
        margin-bottom: 10px;
        display: inline-block;
        font-weight: 300;
        letter-spacing: .5px;
        font-size: 1rem;

        &:hover {
            text-decoration: none;
            color: $color-1;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            margin-bottom: 10px;
        }

        &:lang(hy){
            font-size: 14px;
            margin-bottom: 8px;
        }
    }
}
.footer-socials {
    margin-top: auto;
    padding: 0 10px 0 20px;
    margin-left: auto;

    @media (max-width: 767px){
        flex-wrap: wrap;
        margin-top: 50px;
        margin-left: 0;
    }

    p{
        font-size: 18px;
        margin-right: 20px;
        margin-bottom: 0;

        @media (max-width: 767px){
            font-size: 16px;
            margin-right: 15px;
        }

        &:lang(ru){

            @media (max-width: 410px){
                font-size: 12px;
                margin-right: 10px;
            }
        }
    }
    a:not(.badge-link) {
        width: 35px;
        height: 35px;
        margin: 0 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #172B69;
        border-radius: 50%;
        transition: all .3s;

        &:hover {
            text-decoration: none;
            background-color: $color-1;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}
.footer-app{
    padding: 0 20px;

    img{
        border-radius: 5px;
    }
}
.copyright-container {
    padding: 9px 20px 9px;
    font-size: 14px;
    letter-spacing: 0.42px;
    max-width: 1120px;
    margin: 0 auto;
    font-weight: 500;

    @media (max-width: 767px) {
        font-size: 12px;
        letter-spacing: 0;
    }
    &:lang(hy){
        letter-spacing: 0;
    }
    a {
        color: #FFFFFF;
        margin: 5px 10px;
        display: inline-block;
    }

    p {
        a {
            color: #FFC400;
        }
    }
}

.title-h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 0.55rem;

    @media (max-width: 991px) {
        font-size: 26px;
    }
    @media (max-width: 767px) {
        font-size: 24px;
    }
}



.started-section-register{
    display: flex;
    align-items: center;
    position: relative;
    min-height: calc(100vh - 39px);

    @media (max-width: 991px){
        min-height: 92vh;
    }
}
.started-section-content {
    max-width: 601px;
    padding: 15px 20px 20px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.register-form {
    max-width: 561px;
    width: 100%;
    margin: auto;

    h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 1vh;
        color: $color-2;

        @media (max-width: 991px){
            margin-top: 20px;
            margin-bottom: 25px;
        }
        @media (max-width: 575px){
            font-size: 21px;
            margin-bottom: 15px;
        }
    }
    h4{
        line-height: 1.56;
        font-size: calc(10px + .42vw);
        margin-bottom: 2.6vw;
    }
    .form-group{
        margin-bottom: 16px;
        position: relative;
    }
    .form-control{
        border-color: transparent;
        height: 48px;
        background: #F1F3F7;
        border-radius: 4px;
        padding: 10px 14px;
        color: #2C2F37;
        transition: all .2s;

        &:focus{
            box-shadow: 0 0 0 30px #f1f3f7  inset;
            border-color: #95A3BE;
        }
        &::placeholder{
            color: #989AA0;
        }
        &:hover{
            background: #E6E9F0;
        }
        @media (min-width: 992px) {
            padding-left: 20px;
        }
    }
    .btn{
        background-color: $color-1;
        border-radius: 6px;
        color: #000000;
        transition: all .3s;
        padding: 8px 20px;
        font-size: 18px;
        letter-spacing: 0;

        &:hover{
            background-color: #efc525;
        }
    }
    input:-webkit-autofill{
        -webkit-box-shadow: 0 0 0 30px #f1f3f7  inset !important;
    }

    .invalid{
        color: #F54E4E;
        align-items: center;
        margin-top: 5px;
        display: none;
    }

    .tooltip-info {
        right: 14px;
        top: 16px;
        display: grid;
        align-items: center;
    }
    .tooltip-info img {
        width: 16px;
    }
}
.register-form .form-group.u-is-invalid .form-control{
    background: #FFF5F5;
    color: #F54E4E;
}
.register-form .form-group.u-is-invalid .invalid{
    display: flex;
}
.register-form .form-group.u-is-invalid .form-control::placeholder{
    color: rgba(245, 78, 78, 0.82);
}
.fz-14{
    font-size: 14px;
}


.started-section {
    margin-top: 70px;
    padding: 2.3vw 20px 2.5vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    min-height: 700px;

    @media (max-width: 991px) {
        margin-top: 51px;
        padding: 5vw 20px 2.5vw;
    }
    @media (max-width: 575px) {
        min-height: auto;
        padding: 0 0 50px;
    }
    .register-form h1{
        margin-bottom: 40px;
    }
}
.started-section-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    object-fit: cover;

    @media (max-width: 767px) {
        width: 100%;
        opacity: 0.2;
        height: 100%;
        position: fixed;

        img {
            height: 100%;
            object-fit: cover;
            object-position: left;
        }
    }
}
.started-section form .form-control:not(select){
    padding: 0.375rem 1rem;
}
.started-section form .form-control::placeholder {
    font-size: 1rem;
    color: black;
    opacity: 1;
}
.started-section input:-webkit-autofill::first-line {
    font-size: 16px;
}
.started-section form .btn:hover {
    background-color: #ffffff;
    border-color: $color-1;
    color: black;
}
.form-group-2 .form-group {
    width: 48%;
}
@media (max-width: 575px) {

    .started-section > a > img {
        padding-top: 0;
        margin-left: 0;
    }
    .started-section form h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .started-section form h4 {
        font-size: 16px;
        margin-bottom: 30px;
    }
    .started-section form .form-control {
        height: 45px;
    }
    .started-section form .btn {
        padding: .6em 2.5em;
        font-size: calc(16px + .43vw);
        margin-top: 30px;
    }
    .started-section form .form-control::placeholder {
        padding-left: 0;
    }
}






.toggle-password{
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1;
    cursor: pointer;
    width: 21px;
    height: 20px;
    opacity: .5;

    img{
        width: 20px;
    }
    &.eye-slash{

        .d-none{
            display: block!important;
        }
        .d-block{
            display: none!important;
        }
    }
}
.custom-control-label::before {
    background-color: #ffffff;
    border: solid 1px #707070;
}
.custom-control {
    padding-left: 2rem;
}
.custom-control-label {
    font-size: 14px;
    padding-top: 3px;
}
.custom-control-label::before {
    top: 4px;
    left: -31px;
    width: 20px;
    height: 20px;
}
.custom-control-label::after {
    top: 0.1rem;
    left: -32px;
    width: 23px;
    height: 23px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $color-2;
    background-color: $color-2;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.tooltip-info {
    position: absolute;
    right: 13px;
    top: 13px;

    img{
        width: 17px;
    }
}
.invalid {
    color: red;
}


.pricing-content {

    @media (min-width: 768px) and (max-width: 1199px) {
        flex-wrap: wrap;
        justify-content: center!important;
    }
    @media (max-width: 767px) {
        overflow: hidden;
        overflow-x: auto;
        padding-top: 42px;
        padding-bottom: 20px;
        margin-bottom: 30px;
    }
}
.tab-slider--container {
    width: 100%;
    max-width: 69em;
    margin: 0 auto 0;
    font-size: 16px;
}

.pricing-head {
    padding: 123px 20px 123px;

    @media (max-width: 767px) {
        z-index: 1;
        padding: 80px 15px 40px;

        .toggler-save{
            position: absolute;
            top: 10px;
            right: 0;
            font-size: 12px;
            padding: 4px 15px;
            white-space: nowrap;
        }
    }

    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;

        @media (max-width: 575px) {
            font-size: 20px;
        }

        b {
            color: #7AD7BC;
        }
    }

    h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
        color: #858585;

        @media (max-width: 575px){
            margin-bottom: 30px;
        }

        b {
            color: #7AD7BC;
            font-size: 25px;

            @media (max-width: 575px) {
                font-size: 16px;
            }
        }
    }

    h3 {
        color: #FFD226;
        font-size: 18px;
        margin-bottom: 25px;

        @media (max-width: 575px) {
            font-size: 14px;
        }
    }
}
.price-item {
    background-color: #ffffff;
    width: 350px;
    flex: 0 0 350px;
    border-radius: 16px;
    box-shadow: 0 0 14px 0 rgb(239 235 235 / 80%);
    margin-bottom: 3em;
    padding-bottom: 1.5em;
    min-height: 33.8em;
    display: flex;
    flex-direction: column;
    border: 1px solid #EEEFF5;

    @media (max-width: 1199px){
        box-shadow: none;
        margin: 0 12px;
    }
    @media (min-width: 768px) and (max-width: 1199px){
        margin-bottom: 30px;
    }
    @media (max-width: 767px) {
        width: 300px;
        flex: 0 0 300px;
        position: sticky!important;
        box-shadow: 0 0 40px 10px rgb(0 0 0 / 6%);

        &:nth-child(1){
            left: 0;
        }
        &:nth-child(2){
            left: 26px;
        }
    }

    .btn-yellow {
        padding: .4em .5em;
        border-radius: .35em;
        min-width: 10.6em;

        &:lang(ru) {
            font-size: .9em;
            padding: 0.4em 1.2em;
        }
    }

}
.price-item-head {
    text-align: center;
    border-radius: 8px 8px 0 0;
    background: #EEEFF5;
    padding: 23px 15px 13px;

    h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 0;
        text-transform: uppercase;
    }
}
.price-block {
    line-height: 1;
    overflow: hidden;
    padding: 1.2em 1.3em 0.8em;

    @media (max-width: 575px){
        padding: 1.2em 1em 0.8em;
    }
    span.mt-auto {
        margin-bottom: .7em;
        margin-left: 4px;
    }

    .btn-blue{
        background-color: #ffffff;
        color: #000000;
        border-color: $color-2;
        font-size: 14px;
        padding: 0.56rem 0.9rem;

        &:hover{
            color: #ffffff;
            background-color: $color-2;
        }
    }
}
.save-price {
    line-height: 1.2;
    font-size: 15px;
    color: #646464;
    height: 71px;
    margin-bottom: 16px;

    .toggler-save{
        margin-left: 15px;
    }
}
.old-price {
   position: relative;

    &:after{
        content: "";
        position: absolute;
        left: 18px;
        top: 10px;
        width: 47%;
        height: 1px;
        background-color: #646464;
        transform: rotate(-28deg);
    }
}
.new-price {
    margin-top: 0;
    display: flex;
    justify-content: center;
    min-height: 44px;

    span{
        font-size: 24px;
        margin-right: 2px;
        margin-bottom: auto;
        line-height: 1;
    }
    b{
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        margin-right: 3px;
    }
    p{
        color: #646464;
        font-size: 15px;
        line-height: 22px;
        margin-top: auto;
    }
    .text-center{
        display: flex;
        flex-direction: column;
    }
}

.lets-talk {
    height: 6.8em;
    font-size: 1.9em;
    margin: 0;
}
.lets-talk-monthly {
    height: 4.17em;
}
.circle-ul {
    list-style: none;
    padding-right: 4px;
    padding-left: 23px;
    margin-top: 32px;
    margin-bottom: 32px;

    &.opac50{
        opacity: .2;
    }
    &.font-600{

        li{
            font-weight: 600;
        }
    }

    li {
        position: relative;
        margin-bottom: 11px;
        padding-left: 20px;
        font-weight: 500;
        min-height: 20px;

        &:before, &:after {
            content: "";
            position: absolute;
            background-color: $color-2;
            transform: rotateZ(-46deg);
        }
        &:after{
            left: -14px;
            top: 8px;
            width: 12px;
            height: 2px;
        }
        &:before{
            left: -15px;
            top: 8px;
            width: 2px;
            height: 7px;
        }
        span {
            font-size: 0.87em;

            &.text-blue{
                color: $color-2;
                font-weight: 600;
            }
            b{
                font-weight: 800;
            }
            svg{
                margin-left: 4px;
            }
        }
    }
}
.price-item-popular {
    position: relative;
    border: solid 2px $color-2;
    border-top: none;
    border-radius: 0 0 16px 16px;

    .btn-blue{
        background-color: $color-2;
        color: #ffffff;

        &:hover{
            background-color: #ffffff;
            color: black;
        }
    }
    .price-block{

        @media (min-width: 576px) {
            padding: 1.2em 1.24em 0.8em;
        }
    }
}
.most-popular {
    background-color: $color-2;
    color: #ffffff;
    position: absolute;
    top: -40px;
    left: -2px;
    font-size: 14px;
    padding: 10px 20px 10px 20px;
    border-radius: 16px 16px 0 0;
    text-align: center;
    right: 0;
    width: calc(100% + 4px);
    margin: 0 auto;
    text-transform: uppercase;
}
.toggle-text-content {

    &:before {
        display: none;
    }

    ul {
        display: none;

        @media (min-width: 768px) {
            display: block !important;
        }
    }
}
.toggle-text-link {
    display: block;
    margin: 10px 0;
    color: black;

    &:hover {
        color: black;
        text-decoration: none;
    }
    @media (min-width: 768px) {
        display: none !important;
    }
}
.toggle-text-ellipses {
    color: black;
    line-height: 1;

    @media (min-width: 768px) {
        display: none;
    }
}
.about-7 {
    background: url(https://d35v9chtr4gec.cloudfront.net/uteach/assets/wave-7.svg) no-repeat top;
    background-size: cover;
    padding: 7.25vw 2vw 4vw;

    @media (max-width: 767px) {
        padding: 58px 20px 30px;
        background: url(https://d35v9chtr4gec.cloudfront.net/uteach/assets/wave-7-m.svg) no-repeat top;
        background-size: cover;
        display: flex;
        align-items: center;
    }

    > div {
        max-width: 52vw;
        margin: 0 auto;

        @media (max-width: 767px) {
            max-width: inherit;
            text-align: left !important;
        }
    }

    h2 {
        line-height: 1.6;
        font-size: 1.83vw;
        margin-bottom: 3vw;

        @media (min-width: 1400px) {
            font-size: 1.3vw;
        }
        @media (max-width: 767px) {
            line-height: 1.4;
            font-size: 16px;
        }
    }

    .btn {
        padding: 16px 20px;
    }
}
.contact-section {
    background: url(https://d35v9chtr4gec.cloudfront.net/uteach/assets/contact-bg3.svg) no-repeat bottom;
    background-size: cover;
    padding: 114px 20px 37px;
    margin-bottom: 200px;

    @media (max-width: 767px) {
        padding-bottom: 140px;
        margin-bottom: 125px;
    }
}
.contact-container {
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }

    h1 {
        font-size: 30px;
        margin-bottom: 28px;

        @media (max-width: 767px) {
            position: absolute;
            top: -35px;
            width: 100%;
            text-align: center;
        }
    }
}

.contact-left {
    width: 48%;
    padding-top: 41px;
    padding-right: 30px;

    @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        margin-top: 30px;
    }
}
.contact-section {
    color: #ffffff;
}
.address-item {

    > img {
        margin-right: 25px;
        width: 23px;

        @media (max-width: 991px) {
            margin-right: 12px;
            width: 20px;
        }
    }
}
.address-item-info {
    font-size: 18px;
    margin-bottom: 19px;

    @media (max-width: 991px) {
        font-size: 16px;
    }

    p {
        color: $color-1;
        margin-bottom: 0;
        line-height: 1.2;
    }

    a {
        color: #ffffff;
    }

    h6 {
        font-size: 18px;
        margin-bottom: 15px;
    }
}
.address-item-socials {
    margin-left: 43px;
    padding-top: 11px;

    a {
        margin-right: 15px;
        z-index: 1;
        position: relative;

        &:hover {
            text-decoration: none;
        }
    }
}
.contact-img {
    position: absolute;
    bottom: -15px;
    right: 0;
    width: 63%;
}
.contact-right {
    padding: 40px 53px;
    background-color: #ffffff;
    color: black;
    border-radius: 36px;
    box-shadow: 0 3px 6px #00000029;
    max-width: 549px;
    width: 52%;
    margin-bottom: -160px;

    @media (max-width: 991px) {
        padding: 30px 25px;
    }
    @media (max-width: 767px) {
        width: 100%;
        position: static;
        max-width: 100%;
        margin-top: 20px;
        margin-bottom: 0;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 17px;
        @media (max-width: 991px) {
            font-size: 24px;
        }
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }

    form {
        padding-top: 13px;

        .form-group {
            margin-bottom: 13px;
        }

        .form-control {
            height: 50px;
            border-color: #707070;
            border-radius: 6px;
            padding-left: 25px;

            &::placeholder {
                font-size: 14px;
                color: #554D4D;
            }
        }

        .btn {
            font-size: 20px;
            letter-spacing: 0.6px;
            color: #000000;
            padding: 13px;
            min-width: 184px;
            margin-top: 18px;
            border-radius: 6px;
        }

        textarea.form-control {
            height: 88px;
            resize: none;
        }
    }
}
.video {
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 37vw;
    overflow: hidden;
    background-color: #000000;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #fff;
    padding: 0 15px 40px;
    margin-top: 70px;

    &:after {
        content: "";
        background-color: rgba(36, 65, 153, 0.75);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: -1;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        height: 385px;
    }
    @media (max-width: 991px) {
        margin-top: 50px;
    }
    @media (max-width: 767px) {
        height: 120vw;
        max-height: 455px;
    }

    > div {
        max-width: 810px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .7;
        z-index: -1;
    }

    h1 {
        font-size: 40px;
        margin-bottom: 3.5vw;

        @media (max-width: 1199px) {
            font-size: 30px;
        }
        @media (max-width: 767px) {
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 30px;
        }
    }

    p {
        font-size: 25px;
        @media (max-width: 1199px) {
            font-size: 20px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
            max-width: 485px;
        }
    }
}
.about-2 {
    max-width: 93%;
    margin-top: 50px;

    @media (min-width: 1400px) {
        padding-right: 8.5vw;
        padding-left: 10vw;
        max-width: 100%;
    }
    @media (max-width: 1199px) {
        max-width: 100%;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        padding-bottom: 30px;
        margin: 0 auto 40px;
    }
}
.about-2-text {
    width: 50%;
    padding: 9vw 40px 0;
    flex-grow: 1;

    @media (max-width: 991px) {
        padding: 4vw 30px 0;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 30px 20px;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 50px;

        @media (max-width: 991px) {
            font-size: 22px;
            margin-bottom: 24px;
            font-weight: bold;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    p {
        font-size: 18px;

        @media (max-width: 991px) {
            font-size: 16px;
        }

        strong {
            color: $color-2;
        }
    }
}
.about-2-img {
    width: 50%;
    max-width: 624px;

    @media (max-width: 767px) {
        width: 100%;
        margin: 0 auto;
        max-width: 420px;
    }
    img {
        width: 100%;
        height: auto;
    }
}
.about-3 {
    max-width: 93%;
    margin-top: -9vw;

    @media (min-width: 1400px) {
        margin-top: -5.6vw;
    }
    @media (max-width: 1199px) {
        margin-top: 0;
        max-width: 100%;
    }
    @media (max-width: 767px) {
        width: 100%;
        flex-direction: column-reverse;
        padding-bottom: 30px;
        margin: 0 auto 40px;
    }
}
.about-3-text {
    width: 52%;
    padding: 17vw 40px 0;

    @media (min-width: 1400px) {
        padding-left: 6.4vw;
    }
    @media (max-width: 991px) {
        padding: 6vw 30px 0;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 30px 20px;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 50px;
        @media (max-width: 991px) {
            font-size: 22px;
            margin-bottom: 24px;
            font-weight: bold;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    p {
        font-size: 18px;

        @media (max-width: 991px) {
            font-size: 16px;
        }

        strong {
            color: $color-2;
        }
    }
}
.about-3-img {
    width: 48%;
    max-width: 597px;

    @media (max-width: 767px) {
        width: 100%;
        margin: auto;
        max-width: 420px;
    }
}
.about-features-container {
    max-width: 1070px;
    margin: 8.5vw auto 0;
    padding: 0 20px;

    @media (min-width: 1700px) {
        margin: 3.5vw auto 0;
    }
    @media (min-width: 1200px) {
        max-width: 1200px;
    }
    @media (max-width: 991px) {
        padding: 0 5px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        margin: 0 auto 0;
        width: 98% !important;
    }
}
.prof-img {
    position: absolute;
    top: 51px;
    left: 80px;
    width: 230px;
    will-change: transform;
    transform: scale(.73);
    transform-origin: 107px 52px;
    opacity: 0;
    transition: transform 0.4s, opacity 0.3s 0.2s;

    @media (max-width: 1199px) {
        display: none;
    }
}
.about-features-item {
    width: 33.33%;
    font-size: 18px;
    margin-bottom: 59px;
    padding: 0 15px;
    line-height: 1.65;
    text-align: center;

    &:hover {

        .prof-img {
            opacity: 1;
            transform: scale(1.2);
            image-rendering: pixelated;
            transition: transform 0.4s 0.2s, opacity 0.3s;
        }
    }

    @media (min-width: 1200px) {
        padding: 0 40px;
    }
    @media (max-width: 991px) {
        text-align: center;
        font-size: 15px;
    }
    @media (max-width: 767px) {
        width: 100%;
        font-size: 16px;
        margin: 0 auto 20px;
        text-align: left;
        padding: 0 0 20px;
        display: flex;
        align-items: center;
    }

    strong {
        font-size: 20px;
        font-weight: 500;

        @media (max-width: 991px) {
            font-size: 15px;
        }
        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 1.3;
        }
    }

    img {
        margin-bottom: 18px;
        max-width: 100%;
        height: auto;

        @media (max-width: 767px) {
            margin-right: 5px;
            width: 120px;
        }
    }
}
.about-4 {
    margin-top: 11.3vw;
    min-height: 15vw;

    @media (min-width: 1700px) {
        margin-top: 8.3vw;
    }
    @media (max-width: 767px) {
        overflow: hidden;
        margin: 0 auto;
        padding-top: 50px;
        height: 290px;
    }
}
.about-4-text {
    position: absolute;
    color: #ffffff;
    left: 12.15vw;
    top: 7.7vw;
    max-width: 38vw;

    @media (min-width: 1400px) {
        left: 20.7vw;
        top: 4.9vw;
        max-width: 29vw;
    }
    @media (max-width: 767px) {
        left: 5.15vw;
        top: 92px;
        max-width: 165px;
        display: flex;
        flex-direction: column;
        height: 190px;
    }

    h3 {
        font-size: 2.45vw;
        line-height: 1.5;
        margin-bottom: 1.6vw;

        @media (min-width: 1400px) {
            font-size: 1.7vw;
            margin-bottom: 1.3vw;
        }
        @media (max-width: 767px) {
            font-size: 15px;
            line-height: 2;
            margin-bottom: 1vw;
        }
    }

    h4 {
        opacity: 0.52;
        font-size: 1.5vw;
        line-height: 1.7;

        @media (min-width: 1400px) {
            font-size: 1vw;
        }
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 1.7;
            margin-top: auto;
        }

        span {
            font-size: 1vw;
            display: block;

            @media (min-width: 1400px) {
                font-size: 0.8vw;
            }
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
}
.shef-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 41vw;
    max-width: 560px;

    @media (min-width: 1400px) {
        right: 10%;
    }
    @media (max-width: 767px) {
        bottom: 0;
        right: -160px;
        max-width: 352px;
        width: 98%;
    }

    img {
        width: 100%;
    }
}
.about-5 {
    max-width: 91%;
    margin-top: 6vw;

    @media (min-width: 1400px) {
        max-width: 1546px;
    }
    @media (max-width: 1199px) {
        max-width: 100%;
        margin-bottom: 30px;
    }
}
.about-5-text {
    width: 45%;
    padding: 3.4vw 40px 0;
    flex-grow: 1;

    @media (max-width: 991px) {
        padding: 3.4vw 30px 0;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 30px 20px;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 50px;

        @media (max-width: 991px) {
            font-size: 20px;
            margin-bottom: 24px;
            font-weight: bold;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    p {
        font-size: 18px;

        @media (max-width: 991px) {
            font-size: 16px;
        }

        strong {
            color: $color-2;

            @media (max-width: 991px) {
                color: black;
            }
        }
    }

    ul {
        margin-top: 63px;

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        li {
            font-size: 19px;
            margin-bottom: 22px;

            @media (max-width: 991px) {
                font-size: 14px;
                margin-bottom: 10px;
            }
            @media (max-width: 767px) {
                font-size: 16px;

                &:lang(hy) {
                    font-size: 15px;
                }
            }

            .arrow-pricing {
                margin-right: 20px;
                width: 18px;
                height: 18px;

                @media (max-width: 991px) {
                    margin-right: 15px;
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
}
.about-5-img {
    width: 55%;
    max-width: 679px;

    @media (max-width: 767px) {
        width: 100%;
        margin: 0 auto;
        max-width: 420px;
    }

    img {
        width: 100%;
    }
}
.about-6 {
    max-width: 93%;
    margin-top: -6.5vw;

    @media (min-width: 1400px) {
        margin: 0 auto;
        max-width: 80%;
    }
    @media (max-width: 1199px) {
        margin-top: 0;
        max-width: 100%;
    }
    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}
.about-6-text {
    width: 49%;
    padding: 14.5vw 40px 0 8vw;

    @media (min-width: 1400px) {
        padding: 5.3vw 40px 0 4.55vw;
    }
    @media (max-width: 991px) {
        padding: 3.5vw 30px 0;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 30px 20px;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 50px;

        @media (min-width: 1400px) {
            margin-bottom: 63px;
        }
        @media (max-width: 991px) {
            font-size: 20px;
            margin-bottom: 24px;
            font-weight: bold;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }

    p {
        font-size: 18px;

        @media (max-width: 991px) {
            font-size: 16px;
        }

        strong {
            color: $color-2;
        }
    }
}
.about-6-img {
    width: 51%;
    max-width: 637px;

    @media (max-width: 767px) {
        width: 100%;
        display: none;
    }
}
.static-container {
    max-width: 1000px;
    padding: 130px 20px 80px;
    margin: 0 auto;
    font-size: 18px;
    color: black;
    word-break: break-word;

    @media (max-width: 767px){

        h1{
            font-size: 28px;
        }
        h2{
            font-size: 24px;
        }
    }
}
.faq-head {
    margin-top: 70px;
    background: url(https://d35v9chtr4gec.cloudfront.net/uteach/assets/faq-bg.svg) no-repeat bottom;
    background-size: cover;
    padding: 4.5vw 20px 5.7vw;
    margin-bottom: 30px;
    color: #ffffff;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
        margin-top: 50px;
    }
    @media (max-width: 767px) {
        padding: 30px 20px 60px;
    }

    h1 {
        font-size: 25px;
        text-align: center;
        margin-bottom: 26px;

        @media (max-width: 767px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    form {
        max-width: 495px;
        margin: 0 auto;
    }
}
.form-group-faq {

    .btn {
        position: absolute;
        right: -12px;
        top: -2px;
        background-color: $color-1;
        border-radius: 20px;
        width: 44px;
        height: 44px;
        padding: 7px 13px;

        @media (max-width: 767px) {
            right: 0;
        }
    }

    .form-control {
        height: 40px;
        box-shadow: 0 1px 6px #00000029;
        border-radius: 20px;
        padding-left: 25px;
        padding-right: 45px;
        border: none;

        &::placeholder {
            font-size: 14px;
            color: #9F9F9F;
        }
    }
}
.faq-section {
    background-color: #f5f7fb;
    margin-top: -6vw;
    padding-top: 8vw;
    padding-bottom: 170px;
    margin-bottom: -75px;

    @media (max-width: 767px) {
        margin-top: -70px;
        padding-top: 100px;
    }
}
.faq-accordion {
    max-width: 740px;
    margin: 0 auto;
    padding: 0 20px;

    .card {
        margin-bottom: 15px;
        border: 1px solid #e6e6e6;
        border-radius: 11px !important;
        box-shadow: 0 3px 6px #00000012;
    }

    .card-body {
        padding: 16px 19px 16px 24px;

        @media (max-width: 767px) {
            font-size: 14px;
        }
    }

    .card-header {
        background-color: transparent;
        padding: 16px 19px 16px 24px;
        border: none !important;

        @media (max-width: 767px) {
            padding: 10px 0 10px 15px;
        }

        .btn {
            text-align: left;
            position: relative;
            text-transform: inherit;
            padding: 0;
            white-space: normal;
            background-color: #fff;

            &:after {
                content: "";
                width: 23px;
                height: 23px;
                background-size: 100%;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
                background-repeat: no-repeat;

                @media (max-width: 767px) {
                    right: 10px;
                    width: 22px;
                    height: 22px;
                    top: 0;
                }
            }

            &[aria-expanded="true"] {
                color: $color-2;
            }

            &[aria-expanded="true"]:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_1' data-name='Layer 1' viewBox='0 0 23 23'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23244199;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eminus%3C/title%3E%3Cg id='Group_8042' data-name='Group 8042'%3E%3Cg id='Group_8043' data-name='Group 8043'%3E%3Cg id='add'%3E%3Cg id='Group_8040' data-name='Group 8040'%3E%3Cg id='Group_8039' data-name='Group 8039'%3E%3Cpath id='Path_8670' data-name='Path 8670' class='cls-1' d='M11.5,0A11.5,11.5,0,1,0,23,11.5,11.5,11.5,0,0,0,11.5,0Zm0,22.05A10.55,10.55,0,1,1,22.05,11.5,10.55,10.55,0,0,1,11.5,22.05Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cpath id='Path_8672' data-name='Path 8672' class='cls-1' d='M6,11.5H17a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H6a.5.5,0,0,1-.5-.5A.51.51,0,0,1,6,11.5Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }

            &[aria-expanded="false"]:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.008' height='23.008' viewBox='0 0 23.008 23.008'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23767676;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(174.961 -91.039)'%3E%3Cg transform='translate(-174.961 91.039)'%3E%3Cpath class='a' d='M11.5,0a11.5,11.5,0,1,0,11.5,11.5A11.517,11.517,0,0,0,11.5,0Zm0,22.049A10.545,10.545,0,1,1,22.049,11.5,10.557,10.557,0,0,1,11.5,22.049Z'/%3E%3Cpath class='a' d='M119.608,112.9h-5.752v-5.752a.479.479,0,1,0-.959,0V112.9h-5.752a.479.479,0,0,0,0,.959H112.9v5.752a.479.479,0,0,0,.959,0v-5.752h5.752a.479.479,0,0,0,0-.959Z' transform='translate(-101.873 -101.873)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }

            &:focus {
                box-shadow: none;
            }

            span {
                font-size: 16px;
                display: block;
                padding-right: 40px;

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
    }
}

.support-section {
    padding: 30px 0 80px;
}
.support-container {
    max-width: 1368px;
    margin: 0 auto;
    padding: 0 24px 0 9px;

    @media (max-width: 767px) {
        max-width: 650px;
        padding: 0 9px 0 9px;
    }
    @media (max-width: 575px){
        max-width: 450px;
    }
    > h2 {
        font-size: 25px;
        margin-bottom: 46px;
        @media (max-width: 575px) {
            font-size: 22px;
        }
    }
}
.support-head {
    margin-top: 130px;
    padding: 0 15px;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
        margin-top: 80px;
    }
    @media (max-width: 767px) {
        padding: 30px 20px 30px;
        margin-bottom: 0;
        margin-top: 50px;
    }

    h1 {
        font-size: 25px;
        text-align: center;
        margin-bottom: 26px;

        @media (max-width: 767px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
    }

    form {
        max-width: 495px;
        width: 100%;
        margin: 0 auto;
        border-radius: 22px;
    }
}

.support-content{
    width: 70%;
    padding-right: 2vw;

    @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
    }
}
.support-col{
    width: 33.33%;
    padding: 15px;
    margin-bottom: 15px;

    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 575px){
        width: 100%;
    }
}
.support-item {
    width: 100%;
    color: #000;
    position: relative;

    &:hover {
        text-decoration: none;
        color: black;
        box-shadow: none;

        img{
            transform: scale(1.05);
        }
        h2:before{
            transform: scaleX(1);
        }
    }

    img {
        width: auto;
        max-width: 100%;
        height: 72px;
        margin-bottom: 20px;
        transition: transform .2s;

        @media (max-width: 575px){
            margin-bottom: 10px;
        }
    }

    h2{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        position: relative;

        &:after, &:before{
            content: '';
            position: absolute;
            transition: transform .3s ease;
        }
        &:before{
            left: 0;
            bottom: -6px;
            width: 100%;
            height: 2px;
            background: $color-2;
            transform: scaleX(0);
        }
        @media (max-width: 575px){
            margin-bottom: 10px;
        }
    }
    p {
        font-size: 13px;
    }
}
.support-right{
    width: 30%;

    @media (max-width: 767px) {
        width: 100%;
        padding: 15px;
    }
    h2{
        font-size: 20px;
        font-weight: 600;

        .btn{
            font-size: 20px;
            font-weight: 600;

            @media (min-width: 768px) {
                pointer-events: none;

                svg{
                    display: none;
                }
            }
            &[aria-expanded="true"]{

                svg{
                    transform: rotate(180deg);
                }
            }
            &:focus{
                outline: none;
                box-shadow: none;
            }
            svg{
                transition: transform .3s;
            }
        }
    }
    .collapse{

        @media (min-width: 768px){
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
.support-right-link{
    color: #656e7a;
    font-size: 16px;
    margin-bottom: 15px;
    display: inline-block;

    &:hover{
        color: #656e7a;
    }
    @media (max-width: 1199px){
        font-size: 14px;
    }
    @media (max-width: 767px){
        display: block;
    }
}
.support-single-container {
    max-width: 1372px;
    margin: 0 auto 0;
    padding: 20px 24px 50px;

    @media (max-width: 1499px){
        max-width: 1250px;
    }

    .breadcrumb{
        background-color: transparent;
        padding: 0;
        margin-bottom: 60px;

        .breadcrumb-item{
            color: black;
            font-size: 15px;

            a{
                color: #656e7a;
            }
            @media (max-width: 767px){
                font-size: 14px;
            }
        }
    }

    > h2{
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 36px;

        @media (max-width: 767px){
            font-size: 30px;
        }
    }
}
.support-nav {
    width: 250px;

    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 40px;
    }
}
.support-accordion {

    .btn-link {
        color: black;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        white-space: normal;
        text-align: left;

        &:hover,
        &:focus {
            color: $color-1;
            text-decoration: none;
            outline: none;
            box-shadow: none
        }

        &[aria-expanded="true"] {
            color: $color-1;
        }
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;

        .nav {
            border-left: 1px solid rgba(36, 65, 153, 0.17);
            padding-left: 20px;

            .nav-link {
                padding: 0;
                line-height: 1.35;
                margin-bottom: 17px;
                color: #ADADAD;

                &.active {
                    color: #FFD226;
                }
            }

            .nav-item:last-child .nav-link {
                margin-bottom: 0;
            }
        }
    }
}
.support-single-content {
    margin-left: -15px;
    margin-right: -15px;

    .tab-pane {
        font-size: 18px;

        h3 {
            font-size: 18px;
            margin-bottom: 20px;
        }

        img {
            max-width: 100%;
            height: inherit !important;
        }
    }
}
.support-single-col{
    width: 33.33%;
    padding: 15px;
    margin-bottom: 30px;

    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        margin-bottom: 10px;
    }
}
.support-single-item{

    h2{
        font-size: 20px;
        font-weight: 600;

        .btn{
            font-size: 20px;
            font-weight: 600;
            text-align: left;

            @media (min-width: 768px) {
                pointer-events: none;

                svg{
                    display: none;
                }
            }
            @media (max-width: 767px){
                font-size: 18px;
            }
            &[aria-expanded="true"]{

                svg{
                    transform: rotate(180deg);
                }
            }
            &:focus{
                outline: none;
                box-shadow: none;
            }
            svg{
                transition: transform .3s;
                flex: 0 0 20px;
                height: 20px;
                margin-left: 10px;
            }
        }
    }
    .collapse{

        @media (min-width: 768px){
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
    hr{

        @media (max-width: 767px){
            margin-top: 0;
        }
    }
}
.support-single-container-2{
    display: flex;
    align-items: flex-start;
}
.support-single-topics{
    width: 330px;
    position: sticky;
    top: 65px;
    max-height: calc(100vh - 76px);
    overflow: auto;

    @media (max-width: 991px){
        width: 260px;
    }
    @media (max-width: 767px){
       display: none;
    }
    &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar{
        width: 6px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #b3b3b3;
    }

    > h2{
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
    }
}
.support-single-topics-ul{
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li{
        padding: 8px 0 8px 11px;
        font-size: 14px;

        a{
            color: black;
            padding: 8px 0;
            position: relative;
            display: inline-block;

            &:before{
                content: "";
                position: absolute;
                z-index: -1;
                height: 2px;
                left: 0;
                right: 0;
                bottom: 0;
                background: $color-2;
                transform: scaleX(0);
                transform-origin: 0 50%;
                transition-property: transform;
                transition-duration: 0.3s;
                transition-timing-function: ease-out;
            }
            &:hover{
                text-decoration: none;
                color: $color-2;

                &:before{
                    transform: scaleX(1);
                    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
                }
            }
        }
        &.active{
            border-left: solid 2px $color-2;
            pointer-events: none;

            a{
                color: $color-2;
            }
        }
    }

}
.support-single-text{
    width: calc(100% - 330px);
    max-width: 750px;
    padding-left: 30px;

    @media (max-width: 991px){
        width: calc(100% - 260px);
        padding-left: 20px;
    }
    @media (max-width: 767px){
        width: 100%;
        padding-left: 0;
    }
    img{
        max-width: 100%;
    }
}



.marker-color {
    font-weight: bold;
}
.pricing-container-2 {
    margin: 0 auto 40px;
    padding: 15px;

    h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #9A9A9A;

        a {
            color: $color-2;
            margin-left: 5px;
            text-decoration: underline;
            white-space: nowrap;
        }
    }
}
.discus-modal {

    .contact-right {
        box-shadow: none;
        width: 100%;
        max-width: none;
        margin-bottom: 0;
        padding: 20px 15px;

    }
}

.arrow-pricing {
    width: 12px;
    height: 12px;
    transform: rotate(133deg);
    border-right: 3px solid #ffd226;
    border-top: 3px solid #ffd226;
    display: inline-block;
}

.pricing-migration{
    background-color: #EEEFF5;
    padding: 50px 20px;
    margin-bottom: 60px;
}
.pricing-migration-container{
    max-width: 1078px;
    margin: 0 auto;

    h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
    }
    .price-accordion-col{
        width: 49%;

        @media (max-width: 767px){
            width: 100%;
        }
    }
    .faq-accordion{
        max-width: none;
        margin-top: 43px;
        padding: 0;
    }
    .faq-accordion .card{
        box-shadow: none;
        border-radius: 0!important;
    }
    .faq-accordion .card-header{
        padding: 20px 20px 20px 25px;
    }
    .faq-accordion .card-header .btn span{
        font-weight: 600;
    }
}
.pricing-migration-left{
    width: 64%;
    max-width: 612px;
    margin-top: 54px;

    @media (max-width: 575px){
        width: 100%;
    }
    h3{
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 11px;
    }
    p{
        margin-bottom: 33px;
    }
    ul{
        padding-left: 25px;
        margin-bottom: 50px;
    }
    .btn{
        padding: 14px 21px;
    }
}
.pricing-migration-right{
    width: 35%;
    max-width: 354px;
    margin-top: 42px;

    @media (max-width: 575px) {
        display: none;
    }
}
.pricing-payment{
    max-width: 1100px;
    width: 100%;
    margin: 10px auto 70px;

    > h2{
        margin-bottom: 40px;
    }
    .title-p{
        margin-top: 40px;
    }
    .btn-pulse{
        animation: pulse 1.5s infinite;
        will-change: transform;
        box-shadow: 0 0 0 0 #ffd226;
        font-size: 20px;
    }
}
@keyframes pulse {
    0% {
        transform: scale(.9)
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(#5a99d4, 0);
    }
    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
}
.pricing-payment-images{
    margin-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 5px 18px;
    justify-content: center;

    img{
        width: 146px;
        padding: 15px 15px;
        border-radius: 4px;
        height: 58px;
        object-fit: contain;
        box-shadow: 0 1px 8px #00000024;
    }
}
.payment-see-all{
    margin-top: 15px;
    display: inline-block;
    color: $color-2;
    font-size: 18px;

    &[aria-expanded="true"]{
        font-size: 0;
    }
    &[aria-expanded="true"]:after{
        font-size: 18px;
        content: attr(data-text);
    }
}
.pricing-question-new {
    background-color: #EEEFF5;
    padding: 47px 20px 100px;

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 67px;

        @media (max-width: 767px) {
            margin-bottom: 50px;
            font-size: 22px;
        }
    }
    .faq-accordion{
        max-width: 1150px;
        padding: 0;
    }
    .faq-accordion .card-header {
        padding: 20px 30px 20px 31px;

        @media (max-width: 767px){
            padding: 15px 10px 15px 15px;
        }
    }
    .faq-accordion .card-body{
        padding: 0 60px 20px 31px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        @media (max-width: 767px){
            padding: 0 40px 20px 15px;
        }
    }
    .faq-accordion .card {
        border: none;
        border-radius: 0 !important;
        box-shadow: none;
        margin-bottom: 20px;

        @media (max-width: 767px){
            margin-bottom: 15px;
        }
    }

    .faq-accordion .card-header .btn span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}
.price-accordion-col{
    width: 48.6%;

    @media (max-width: 767px){
        width: 100%;
    }
}

.price-credit-info{
    color: #000000;
    margin: 29px 0 0;
}
.pricing-trust{
    min-height: 150px;
    padding: 24px;
    margin-bottom: 82px;

    @media (max-width: 767px){
        margin-bottom: 50px;
    }
}
.pricing-features-container{
    max-width: 1115px;
    margin: 0 auto;
    padding: 0 20px 110px;

    @media (max-width: 767px){
        padding-bottom: 60px;
    }
    h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 40px;

        @media (max-width: 767px){
            margin-bottom: 30px;
        }
    }
}
.pricing-features-item{
    width: 32%;
    max-width: 345px;
    border-radius: 6px;
    padding: 24px 30px;
    background: rgba(238, 239, 245, 0.48);
    margin-bottom: 20px;

    @media (max-width: 991px) {
        padding: 20px 24px;
    }
    @media (max-width: 767px) {
        width: 49%;
        padding: 20px 15px;
    }
    @media (max-width: 575px) {
        width: 100%;
    }
    h3{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }
    p{
        color: #858585;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0;
    }
    img{
        margin-bottom: 22px;

        @media (max-width: 575px){
            margin-bottom: 14px;
        }
    }
}
.pricing-custom{
    background: #EEEFF5;
    padding: 38px 20px 67px;

    @media (max-width: 767px){
        padding: 38px 20px 50px;
    }
}
.pricing-custom-container{
    max-width: 1270px;
    margin: 0 auto;

    h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
    }
}
.pricing-custom-block{
    margin-top: 57px;

    @media (max-width: 767px){
        margin-top: 40px;
    }
}
.pricing-custom-item{
    width: 49%;
    background-color: #ffffff;
    max-width: 620px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px){
        width: 100%;
        max-width: none;
    }
    h3{
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: #ffffff;
        padding: 18px 39px;

        @media (max-width: 1199px) {
            font-size: 17px;
            padding: 10px 15px;
        }
    }
    p{
        font-weight: 400;
        font-size: 14px;
        padding: 22px 34px 20px;
        line-height: 1.7;

        @media (max-width: 1199px){
            padding: 20px 15px 20px;
        }
    }
    div{
        padding: 10px 25px;
        margin-bottom: 6px;
        margin-top: auto;
    }
    a{
        color: #000000;

        &:hover{
            color: #000000;
        }
    }
    .bg-green, .bg-blue{
        opacity: .9;
    }
}
.bg-blue{
    background-color: $color-2;
}
.bg-green{
    background-color: #34A29B;
}
.pricing-customers{
    padding: 75px 20px;

    h2{
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;

        @media (max-width: 767px){
            margin-bottom: 10px!important;
        }
    }
}
.pricing-customers-container{
    max-width: 1270px;
    margin: 53px auto 0;

    @media (max-width: 1199px){
        max-width: 833px;
    }
}
.pricing-customers-item{
    width: 32.127%;
    background-color: #f5f7fb;
    border-radius: 15px;
    padding: 30px;
    margin-bottom: 25px;
    color: black;

    &:hover{
        text-decoration: none;
    }

    @media (max-width: 1199px) {
        width: 49%;
        margin-bottom: 14px;
    }
    @media (max-width: 767px){
        width: 100%;
        max-width: 408px;
        margin: 0 auto 20px;
        padding: 16px;
    }
    > img{
        width: 100%;
        height: 131px;
        object-fit: cover;
        object-position: top;
        border-radius: 9px 9px 0 0;
    }
    p{
        line-height: 26px;
        margin-bottom: 20px;
    }
}
.pricing-customers-item-2{

    .pricing-customers-brand{
        background-color: #131313;
        color: #ffffff;
    }
}
.pricing-customers-brand{
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 10px;
    margin-top: 2px;

    img{
        height: 21px;
        width: auto;
        max-width: 135px;
    }
   span{
       font-size: 14px;

       @media (max-width: 450px){
           font-size: 12px;
       }
   }
}
.pricing-customers-content{
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;

    > p{
        padding: 0 15px;

        @media (max-width: 991px){
            padding: 0;
        }
    }
}
.pricing-customers-author{
    margin-top: auto;

    img{
        color: black;
        width: 50px;
        height: 50px;
        margin-right: 15px;
        border: solid 1px #d2d2d2;
        border-radius: 50%;
        object-fit: cover;
    }
    h3{
        font-size: 15px;
        margin-bottom: 3px;
        font-weight: 600;
    }
    p{
        font-size: 14px;
    }
}
/*Mobile View*/

.mobile-table {

    @media (max-width: 767px) {
        td:last-child {
            text-align: inherit;
            border-right: none;
            padding: .4rem 0.75rem;
        }
        td,
        tr {
            display: block;

            .btn,
            button {
                display: block;
                width: 100%;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        tr {
            margin-bottom: 10px;
        }
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        td {
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 35% !important;
            text-align: left;
        }
        td:before {
            content: attr(data-label);
            display: inline-block;
            margin-left: -50%;
            width: 65%;
            white-space: nowrap;
            font-weight: bold;
        }
    }
}

.wave-svg {
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: -21%;

    @media (max-width: 767px) {
        bottom: 0;
    }
}

.media-slider-section {
    padding: 124px 15px 0;

    @media (max-width: 991px) {
        padding: 120px 0 0;
    }
    @media (max-width: 767px) {
        padding: 30px 0 0;
    }

    h2 {
        font-size: 30px;
        margin-bottom: 50px;

        @media (max-width: 991px) {
            font-size: 20px;
            margin-bottom: 24px;
            font-weight: bold;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
    }
}

.media-slider {
    max-width: 1171px;
    margin: 0 auto;
    padding: 15px 60px;

    @media (max-width: 1199px) {
        padding: 15px 45px;
        max-width: 800px;
    }
    @media (max-width: 767px) {
        max-width: 384px;
        padding: 15px 30px;
    }

    .slick-slide {

        @media (max-width: 991px) {
            padding: 12px;
        }
        @media (max-width: 767px) {
            padding: 5px;
        }
    }

    .slick-prev,
    .slick-next {
        top: 143px;
    }

    .slick-next:before,
    .slick-prev:before {
        border: solid #000000;
        border-width: 0 2px 2px 0;
        padding: 8px;
    }
}

.media-slider-item {
    max-width: 315px;
    color: black;

    &:hover {
        text-decoration: none;
        color: $color-2;

        h3 {
            color: $color-2;
        }
    }

    img {
        margin-bottom: 17px;
        border-radius: 28px;
        object-fit: cover;
        max-width: 100%;
        height: 200px;

        @media (max-width: 767px) {
            height: 56vw;
            max-height: 220px;
        }
    }

    h3 {
        font-size: 18px;
        color: #000000;
        padding-left: 9px;
        letter-spacing: 0.5px;
        margin-bottom: 14px;
    }

    p {
        padding-left: 9px;
        line-height: 1.4;
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
.animated.hinge {
    animation-duration: 2s;
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.fadeInDown {
    animation-name: fadeInDown;
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}
.fadeInLeft {
    animation-name: fadeInLeft
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}
.fadeInRight {
    animation-name: fadeInRight
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}
.fadeInUp {
    animation-name: fadeInUp
}

.thank-register {
    display: flex;
    position: relative;
    height: 100vh;

    @media (max-width: 575px) {
        min-height: 400px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        .thank-register-img-2 {
            display: none;
        }
    }
}
.thank-register-text {
    position: absolute;
    left: 21%;
    right: 0;
    top: 30%;
    width: 45%;
    margin: 0 auto;
    padding: 20px;

    @media (max-width: 991px) {
        left: 37%;
        top: 26%;
        width: 60%;
    }
    @media (max-width: 575px) {
        position: static;
        width: 100%;
        text-align: center;
    }

    h1 {
        color: $color-2;
        font-size: 30px;
        margin-bottom: 19px;
        @media (max-width: 575px) {
            font-size: 24px;
        }
    }

    p {
        color: black;
        font-size: 22px;
        font-weight: 600;

        @media (max-width: 575px) {
            font-size: 18px;
        }
    }
}
.thank-register-img-1 {
    width: 38%;
    margin-bottom: 70px;
    object-fit: contain;

    @media (max-width: 575px) {
        margin-top: 30px;
    }
}
.thank-register-img-2 {
    max-width: 100%;
    width: 34%;
    margin-left: auto;
    margin-top: auto;
}
.my-modal {

    .btn-yellow {
        padding: 17px 60px;
        font-size: 18px;
        letter-spacing: 1px;
        margin-top: 45px;

        @media (max-width: 767px) {
            margin-top: 30px;
            padding: 10px 45px;
        }
    }

    h5 {
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 0;
        line-height: 1.5;

        @media (max-width: 767px) {
            font-size: 18px;
            line-height: 1.4;
        }
    }

    .modal-body {
        padding: 1rem 1.9rem;

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    .modal-content {
        border-radius: 11px;
        border: 1px solid #CECECE;
        box-shadow: 0 3px 6px #0000001a;
        padding-bottom: 20px;

        @media (max-width: 767px) {
            padding-bottom: 15px;
        }
    }

    .modal-dialog {

        @media (min-width: 768px) {
            max-width: 700px;
            padding-top: 70px;
        }
    }

    .modal-header .close {
        position: absolute;
        right: 22px;
        opacity: 1;
        z-index: 1;
        color: #7e7e7e;

        &:focus {
            outline: none;
        }
    }

    form {
        max-width: 445px;
        margin: 45px auto 0;

        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }

    .form-control {
        background-color: #ECF3FE;
        min-height: 50px;
        border-radius: 6px;
        border-color: transparent;
        padding-left: 23px;

        &::placeholder {
            opacity: 1;
            color: #554D4D;
        }
    }
}
.modal-head-img {
    width: 219px;
    margin: -100px auto 7px;

    @media (max-width: 767px) {
        width: 109px;
        margin-top: 0;
        margin-bottom: 0;
    }
}


.gg-close-o {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid;
    border-radius: 40px
}
.gg-close-o::after,
.gg-close-o::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 3px
}
.gg-close-o::after {
    transform: rotate(-45deg)
}

.nav-scroll {

    @media (min-width: 992px) {
        .teacher-video-head, .teacher-head, .teacher-single-head {
            margin-top: 0;
        }
        .teacher-video-right {
            max-height: calc(100vh - 70px);
        }
    }
    @media (min-width: 1200px){

        .features-collapse{
            top: 55px;
        }
    }
}
.toggle, .toggler {
    display: inline-block;
    vertical-align: middle;
    margin: 0 7px 0;

    @media (max-width: 767px) {
        margin: 0 4px 25px;
    }
}
.toggler {
    color: #999999;
    transition: 0.2s;
    font-size: 16px;

}
.toggler--is-active {
    color: $color-2;
    text-shadow: 0 0 black;
}
.b {
    display: block;
}
.toggle {
    position: relative;
    width: 60px;
    height: 32px;
    border-radius: 100px;
    background-color: $color-2;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgb(0 0 0 / 5%);
    border: solid 1px #ffffff;
}
.check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
}
.check:checked ~ .switch {
    right: 2px;
    left: 52%;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: .08s, 0s;
}
.switch {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 52%;
    background-color: #ffffff;
    border-radius: 36px;
    z-index: 1;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, .08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.toggler-save{
    color: #ffff;
    background-color: #00B67A;
    padding: 6px 15px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
}
.hide {
    display: none;
}
.pt-70 {
    padding-top: 70px;

    @media (max-width: 991px) {
        padding-top: 50px;
    }
}
.was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none;
}
.user-verified-page {

    meter {
        height: 20px;
        width: 25%;
    }

    button {
        border: none;
        color: white;
        background-color: #000;
        border-radius: 50%;
        height: 25px;
    }

    button:first-of-type {
        margin-left: 5px;
    }

    meter::-webkit-meter-bar {
        background-image: none;
        background-color: whitesmoke;
        box-shadow: 0 5px 5px -5px #333 inset;
        border-radius: 25px;
    }

    meter::-webkit-meter-optimum-value {
        border-radius: 25px;

    }

    meter::-webkit-meter-optimum-value {
        width: 100%;
        background-image: linear-gradient(90deg, var(--color) 0%, var(--color) 100%);
    }
}
.page-404{
    height: 100vh;
    padding: 0;

    header{
        padding: 7px 15px 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
    .navbar-brand{
        margin-left: 7vw;
    }
}

.img-404{
    width: 50%;
    padding: 40px 0;
    height: calc(100% - 55px);

    @media (max-width: 767px) {
        width: 100%;
        height: auto;
    }
}
.img-404-text{
    position: absolute;
    left: 16%;
    top: 11%;
    width: 70%;

    @media (max-width: 1100px) {
        left: 10%;
        width: 80%;
    }
    > span{
        font-size: 37px;
        margin-bottom: 11px;
        display: block;
    }
    h1{
        font-size: 19px;
        line-height: 1.4;
        margin-bottom: 33px;
    }
}

.text-404{
    width: 50%;
    height: calc(100% - 55px);
    background-color: #EFF0F5;
    padding: 9.5vw 15px 30px 5.6vw;

    @media (max-width: 1100px){
        padding: 65px 15px 30px 30px;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 30px 20px;
        height: auto;
    }

    h2{
        font-size: 27px;
        margin-bottom: 24px;

        b{
            color: #2F4A9E;
        }
    }
}
.list-404{
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;

    li{
        margin-bottom: 18px;
        display: flex;
        align-items: flex-start;
        font-size: 19px;

        &::before{
            content: "";
            border-radius: 50%;
            background-color: #2F4A9E;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-right: 11px;
            flex: 0 0 10px;
            margin-top: 8px
        }
        a{
            text-decoration: underline;
            color: #3381E3;

            &:hover{
                color: #1f4c86;
            }
        }
    }
}


#toast-container > div {
    opacity: 1!important;
}

figure.media > div[data-oembed-url] {
    width: 100%;
}


.affilate-head{
    padding: 92px 0 32px;
    background-color: #fbfbfd;
    border-radius: 0 0 247px 0;

    @media (max-width: 767px){
        padding: 80px 0 65px;
        border-radius: 0 0 101px 0;
    }
}
.affilate-head-container{
    margin: 0 auto;
    max-width: 1205px;
    padding: 0 20px;
    justify-content: space-between;
}
.affilate-head-left{
    width: 53%;
    max-width: 510px;

    @media (max-width: 767px){
        width: 100%;
    }
    h1{
        font-size: 30px;
        line-height: 1.34;
        margin-bottom: 22px;

        @media (max-width: 767px){
            font-size: 24px;
        }
    }
    p{
        font-size: 18px;
        margin-bottom: 44px;
    }
    .btn{
        font-size: 20px;
        padding: 0.65rem 1.4rem;

        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.affilate-head-right{
    width: 45%;
    max-width: 523px;

    @media (max-width: 767px){
        display: none;
    }
    img{
        max-width: 100%;
    }
}

.affilate-steps-container{
    max-width: 1100px;
    margin: 0 auto;
    padding: 86px 20px 112px;

    h2{
        font-size: 30px;

        @media (max-width: 991px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 24px;
        }
        b{
            font-weight: 600;
            letter-spacing: 0.027em;
        }
    }
    .btn{
        font-size: 20px;
        padding: 0.65rem 1.4rem;

        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.circle-affilate{
    width: 18px;
    height: 18px;
    background-color: #F5D328;
    border-radius: 50%;
    border: 3px solid #F2F2F2;
}
.affilate-steps-list{
    list-style: none;
    display: flex;
    align-items: center;
    margin: 74px 0 63px;
    position: relative;
    padding: 0;

    @media (max-width: 767px){
        flex-direction: column;
        align-items: flex-start;
        padding-left: 50px;
        padding-top: 10px;
        margin: 40px 0 40px;
    }
    &:after{
        content: "";
        position: absolute;
        left: 9%;
        top: 45px;
        width: 82%;
        z-index: -1;
        height: 2px;
        background-color: rgb(180 180 180 / 17%);

        @media (max-width: 767px){
            width: 2px;
            height: 95%;
            top: 0;
            left: 29px;
        }
    }
    li{
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 767px){
            align-items: flex-start;
            position: relative;
            width: 100%;
            margin-bottom: 10px;
        }
        span{
            font-weight: 300;
            font-size: 20px;
            color: #888991;

        }
        .circle-affilate{
            margin: 7px 0;

            @media (max-width: 767px){
                position: absolute;
                left: -29px;
                top: 2px;
            }
        }
        p{
            color: #34383D;
            font-size: 18px;
            font-weight: 600;
            text-align: center;

            @media (max-width: 991px){
                font-size: 14px;
            }
            @media (max-width: 767px){
                text-align: left;
            }
        }
    }
}

.affilate-3{
    background-color: #FBFBFD;

    @media (max-width: 1199px) {
        align-items: center;
    }

    picture{
        width: 42%;
        max-width: 561px;

        @media (max-width: 767px){
            order: 1;
            width: 100%;
        }
        img{
            width: 100%;
        }
    }
}
.affilate-3-info{
    width: 58%;
    padding: 98px 20px 20px 6.5vw;

    @media (max-width: 1199px){
        padding: 24px 15px 24px 4vw;
    }
    @media (max-width: 767px){
        width: 100%;
        padding: 50px 30px;
    }
    @media (min-width: 1500px) {
        padding: 98px 20px 20px 11vw;
    }
    h2{
        font-weight: 600;
        font-size: 30px;
        letter-spacing: 0.027em;
        margin-bottom: 3.2vw;

        @media (max-width: 991px){
            margin-bottom: 2vw;
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 24px;
            margin-bottom: 15px;
        }
    }
}
.ul-check-block{
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 991px){
        margin-bottom: 20px;
    }
}
.ul-check{
    font-size: 18px;
    margin-bottom: 37px;
    list-style-type: none;
    padding: 0 63px 0 0;
    max-width: 50%;

    &:last-child{
        padding-right: 0;
    }
    @media (max-width: 1199px){
        font-size: 16px;
    }
    @media (max-width: 991px){
        padding: 0 25px 0 0;
        max-width: 100%;
        margin-bottom: 0;
    }

    li{
        position: relative;
        padding: 0 0 15px 34px;
        color: #34383D;

        &:before{
            content: " ";
            display: block;
            border-radius: 0.8em;
            height: 20px;
            width: 20px;
            position: absolute;
            left: 0;
            top: 13px;
            margin-top: -0.5em;
            background-color: $color-2;
        }
        &:after{
            content: " ";
            display: block;
            width: 6px;
            height: 11px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            position: absolute;
            left: 7px;
            top: 11px;
            margin-top: -0.2em;
            transform: rotate(45deg);
        }

        @media (max-width: 991px){
            padding: 0 0 10px 30px;
        }
    }
}

.affilate-4{
    padding: 104px 20px 128px;

    h2{
        font-weight: 600;
        font-size: 30px;
        letter-spacing: 0.027em;
        margin-bottom: 84px;

        @media (max-width: 991px){
            font-size: 26px;
            margin-bottom: 50px;
        }
        @media (max-width: 767px){
            font-size: 24px;
        }
    }
}
.affilate-4-container{
    max-width: 1181px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (max-width: 767px){
        margin-left: -12px;
        margin-right: -12px;
    }
}
.affilate-4-item{
    width: 25%;
    padding: 0 39px;

    @media (max-width: 1199px){
        padding: 0 20px;
    }
    @media (max-width: 991px){
        text-align: center;
        padding: 0 12px;
    }
    @media (max-width: 767px){
        width: 50%;
        margin-bottom: 20px;
    }
    img{
        margin-bottom: 28px;
    }
    p{
        font-size: 18px;
        line-height: 25px;
        color: black;

        @media (max-width: 991px){
            font-size: 16px;
        }
    }
}


.affilate-calc{
    background-color: #172758;
    color: #ffffff;
    margin-bottom: 65px;
}
.affilate-calc-container{
    max-width: 1034px;
    padding: 27px 20px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto -110px;
    flex-wrap: wrap;
}
.affilate-calc-text{
    width: calc(100% - 420px);
    padding: 62px 20px 62px 0;
    max-width: 435px;

    @media (max-width: 767px){
        width: 100%;
        max-width: inherit;
        text-align: center;
        padding: 30px 0 25px 0;
    }
    h2{
        font-weight: 600;
        font-size: 30px;
        letter-spacing: 0.027em;
        line-height: 1.5;
        margin-bottom: 33px;

        @media (max-width: 991px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 24px;
            margin-bottom: 15px;
        }
    }
    p{
        font-size: 20px;

        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.affilate-calculate{
    width: 411px;
    padding: 20px;

    @media (max-width: 767px){
        width: 100%;
        padding: 20px 5px;
        max-width: 350px;
        margin: 0 auto;
    }

    .form-group {
        margin-bottom: 26px;

        label{
            margin-bottom: 10px;
        }
    }
}
.affilate-calculate-block{
    color: black;
    background-color: #F2F3F8;
    padding: 25px 27px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 32px;

    h3{
        font-size: 20px;
        margin-bottom: 35px;
    }
    .form-control{

        &:focus{
            box-shadow: none;
        }
    }
}
.affilate-many{
    border-radius: 44px;
    font-weight: 500;
    background-color: #FFD226;
    display: block;
    text-align: center;
    padding: 3px;
    transform: scale(1.33);
    margin-bottom: 48px;
    font-size: 18px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

    b{
        font-size: 30px;
        font-weight: 500;
        vertical-align: top;
        line-height: 1.5;
    }
}
.range{

    input,
    output {
        display: inline-block;
        vertical-align: middle;
        font-size: 1em;
        font-family: Arial, sans-serif;
    }

    input[type="number"] {
        width: 60px;
        padding: 2px 5px;
        border: 1px solid #C7C9D2;
        border-radius: 3px;
        margin-bottom: 5px;
    }

    input[type="range"]:focus,
    input[type="number"]:focus {
        outline: none;
    }

    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        height: 7px;
        border-radius: 5px;
        background-size: 20% 100%;
        background: #ffffff linear-gradient(#fdd025, #fdd025) no-repeat;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #FFD226;
        cursor: ew-resize;
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
        transition: background .3s ease-in-out;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #FFD226;
        cursor: ew-resize;
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
        transition: background .3s ease-in-out;
    }

    input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #FFD226;
        cursor: ew-resize;
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
        transition: background .3s ease-in-out;
    }

    input[type="range"]::-webkit-slider-thumb:hover {
        background: #FFD226;
    }

    input[type="range"]::-moz-range-thumb:hover {
        background: #FFD226;
    }

    input[type="range"]::-ms-thumb:hover {
        background: #FFD226;
    }

    input[type=range]::-webkit-slider-runnable-track  {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type=range]::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    input[type="range"]::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
}

.affilate-6{
    max-width: 1140px;
    padding: 143px 20px 120px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 767px){
        padding: 100px 20px 60px;
    }
    h2{
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0.027em;
        line-height: 1.5;
        margin-bottom: 26px;

        @media (max-width: 991px){
            font-size: 26px;
        }
        @media (max-width: 767px){
            font-size: 24px;
        }
    }
    h3{
        font-weight: 400;
        font-size: 20px;
        line-height: 1.45;

        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.affilate-6-container{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 55px;

    @media (max-width: 767px){
        margin-top: 30px;
    }
}
.affilate-6-item-block{
    width: 33.33%;
    padding: 20px;
    margin-bottom: 10px;

    @media (min-width: 768px) and (max-width: 991px) {
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 0;
    }
}
.affilate-6-item{
    display: flex;
    align-items: center;
    padding: 15px;
    background: linear-gradient(90deg,#fff 0%, #fff 24.48%, #f2f3f8 100%);
    border-radius: 50px;
    min-height: 96px;

    img{
        margin-right: 30px;
    }
    p{
        font-size: 18px;
        margin-bottom: 0;
    }
}

.affilate-7{
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 12.2vw;

    @media (max-width: 1500px){
        padding: 30px 7.2vw;
    }
    @media (max-width: 1199px){
        padding: 30px 25px;
    }
    @media (max-width: 767px){
        justify-content: center;
        padding: 30px 20px;
    }
    h2{
        width: calc(100% - 210px);
        max-width: 759px;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0.7px;
        line-height: 1.55;

        @media (max-width: 1500px){
            font-size: 25px;
        }
        @media (max-width: 1199px){
            font-size: 24px;
            margin: 0;
            line-height: 1.4;
        }
        @media (max-width: 767px) {
            font-size: 22px;
            margin-bottom: 20px;
            line-height: 1.3;
            text-align: center;
            width: 100%;
        }
        &:lang(hy){
            font-weight: 400;
            width: calc(100% - 230px);

            @media (max-width: 767px){
                line-height: 1.4;
                font-size: 20px;
                width: 100%;
            }
        }
    }

    .btn{
        font-size: 20px;
        padding: 0.65rem 1.5rem;

        @media (max-width: 767px){
            font-size: 18px;
        }
    }
    > picture{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;

        img{
            height: 100%;
            object-fit: cover;
        }
    }
}

.affilate-8{
    background-color: #FBFBFD;
    padding: 77px 20px;

    h2{
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0.7px;
        margin-bottom: 26px;
    }
}

.affilate-9{
    padding: 66px 20px;

    h2{
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0.7px;
        margin-bottom: 24px;

        @media (max-width: 767px){
            font-size: 24px;
        }
    }
    h3{
        font-size: 20px;
        font-weight: 400;

        @media (max-width: 767px){
            font-size: 18px;
        }
        a{
            color: $color-2;
        }
    }
}

.fz-25{
    font-size: 25px;

    @media (max-width: 767px){
        font-size: 20px;
    }
}
.help-question-modal{

    .modal-dialog{

        @media (min-width: 768px){
            max-width: 660px;
        }
    }
    .modal-content{
        border-radius: 11px;
        border: 1px solid #cecece;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
    }
    .btn-yellow{
        font-size: 18px;
        letter-spacing: .06em;
        padding: 1em 1.7em;
    }
    .gg-close-o{
        background-color: #BCC2CF;
        border-color: #BCC2CF;
    }
    .gg-close-o::after, .gg-close-o::before{
        background-color: #ffffff;
    }
    button.close{
        position: absolute;
        right: 24px;
        top: 22px;
        opacity: 1;
    }
}
.help-question-modal-img{
    padding: 15px;

    img{
        width: 230px;
        margin: -74px auto 6px;
    }
}

#pr-box {
    font: 13px/16px sans-serif;
    position: absolute;
    z-index:1000;
    display:none;
    width:300px;
    max-width:100%;
}
#pr-box i {
    width: 0;
    height: 0;
    margin-left: 20px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #f1f1f1;
}
#pr-box-inner {
    margin-top: 6px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    border-radius: 2px;
}
#pr-box p {
    padding:20px;
    border-radius: 2px 2px 0 0;
}
#pr-box ul {
    padding:7px;
    border-radius: 0 0 2px 2px;
}
#pr-box ul li {
    list-style: none;
    padding:7px;
}
#pr-box ul li span {
    width:15px;
    height:15px;
    display:block;
    float:left;
    border-radius:100%;
    margin-right:15px;
}
#pr-box.light {
    color:#2d2f31;
}
#pr-box.light p {
    background-color: #f1f1f1;
    color: #000000;
    margin-bottom: 0;
}
#pr-box.light ul {
    background-color:#f1f1f1;
}
#pr-box.light ul li span {
    background-color:#ffffff;
    border:3px solid $color-2;
}
#pr-box.light ul li span.pr-ok {
    background-color:$color-2;
    border:3px solid $color-2;
}
#pr-box.dark {
    color:#f1f1f1;
}
#pr-box.dark p {
    background-color:#23a86d;
}
#pr-box.dark ul {
    background-color:#2d2f31;
}
#pr-box.dark ul li span {
    background-color:#2d2f31;
    border:3px solid #23a86d;
}
#pr-box.dark ul li span.pr-ok {
    background-color:#23a86d;
    border:3px solid #23a86d;
}


.gdpr-head{
    margin-top: 70px;
    padding: 70px 20px;
    background: linear-gradient(154deg, $color-2 0%, $color-2 15.28%, #204fa0 36.16%, #132d78 55.79%, #09155b 73.56%, #030749 88.89%, #010243 100%);

    @media (max-width: 991px) {
        margin-top: 50px;
    }
    @media (max-width: 767px){
        padding: 45px 20px;
    }
}
.gdpr-head-container{
    max-width: 1060px;
    margin: 0 auto;
    color: #ffffff;
}
.gdpr-head-left{
    width: 56%;
    max-width: 564px;
    padding-right: 15px;

    @media (max-width: 767px){
        max-width: none;
        width: 100%;
    }
    i{
        font-size: 20px;
        font-weight: 200;
        margin-bottom: 12px;
        display: inline-block;

        @media (max-width: 767px){
            font-size: 18px;
        }
    }
    h1{
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 31px;

        @media (max-width: 991px){
            font-size: 26px;
            margin-bottom: 25px;
        }
        @media (max-width: 767px){
            font-size: 22px;
        }
    }
    p{
        font-size: 18px;
        font-weight: 300;
        line-height: 1.55;

        @media (max-width: 991px){
            font-size: 16px;
        }
    }
}
.gdpr-head-img{
    width: 44%;
    max-width: 364px;

    @media (max-width: 767px){
        width: 100%;
        margin: 0 auto;
    }
    img{
        max-width: 100%;
    }
}
.gdpr-container-2{
    max-width: 830px;
    margin: 0 auto;
    padding: 67px 20px;

    h2{
        font-size: 30px;
        margin-bottom: 46px;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
    p{
        font-size: 18px;
        line-height: 1.55;
        margin-bottom: 50px;

        @media (max-width: 767px){
            font-size: 16px;
        }
    }
    .btn-yellow{
        font-size: 20px;

        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.features-10{
    background-color: #FBFBFD;
    padding: 70px 20px 65px;

    .title-h2{
        margin-bottom: 47px;

        @media (max-width: 767px){
            font-size: 24px;
        }
    }

    .btn{
        font-size: 20px;
        padding: 0.65rem 1.4rem;
        will-change: transform;
        box-shadow: 0 0 0 0 rgb(255 210 38);
        animation: pulse 1.5s infinite;

        &:hover{
            animation: none;
        }
    }
}
@keyframes pulse {
    0% {
        transform: scale(.9)
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(#5a99d4, 0);
    }
    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
}

.cookie-section{
    position: sticky;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 98%;
    max-width: 1186px;
    margin: 0 auto;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    background: #fff;
    z-index: 10;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

    @media (max-width: 991px){
        padding: 15px 20px;
    }
    @media (max-width: 767px){
        flex-wrap: wrap;
        padding-top: 40px;
    }
    > img{
        margin-right: 20px;

        @media (max-width: 767px){
            display: none;
        }
    }
    p{

        @media (max-width: 991px) {
            font-size: 14px;
            line-height: 1.4;
        }
        @media (max-width: 767px){
            margin-bottom: 10px;
        }
    }
    .btn{
        border-radius: 6px;
        border: 1px solid #c4c4d2;
        margin-left: 12px;
        white-space: nowrap;
        font-size: 14px;
        padding: 9px 17px;
        color: #000000;
        margin-bottom: 8px;

        &:hover{
            background-color: #f1f1f1;
        }
    }
    .btn-close{
        padding: 3px 10px;
        border: none;
        background-color: #ffffff;
        margin-left: 25px;
        transition: transform 0.3s;
        position: absolute;
        top: -30px;
        right: 15px;
        border-radius: 4px 4px 0 0;

        &:hover{
            transform: scale(1.2);
        }
    }
}

.appsumo-custom-banner {
    position: fixed;
    display: none;
    bottom: 0;
    z-index: 999999;
    left: 0;

    .appsumo-custom-banner-btn {
        position: absolute;
        right: 25px;
        top: 20px;
        border: 0;
        background: none;
    }
}

@import 'toastr';




//landing africa
.land-africa-0-head{
    padding: 25px 30px 25px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    max-width: 1304px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1199px){
        padding: 15px 20px 15px 20px;
    }
    .btn-julia{
        margin: 0;
        width: auto;
    }
}
.land-africa-0{
    padding: 10px 30px 30px;
    min-height: calc(100vh - 90px);
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: 1199px){
        padding: 20px 20px 30px;
    }
}
.land-africa-0-bg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;

    @media (max-width: 767px){
        display: none;
    }
}
.land-africa-0-container{
    max-width: 1246px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 20px;
    color: #000000;

    @media (max-width: 767px){
        flex-direction: column;
    }
}
.land-africa-0-left{
    width: 45%;
    max-width: 560px;

    @media (max-width: 767px){
        width: 100%;
        max-width: none;
    }
    h1{
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 15px;

        @media (max-width: 991px){
            font-size: 30px;
        }
    }
    p{
        font-size: 22px;
        margin-bottom: 38px;

        @media (max-width: 991px){
            font-size: 20px;
            line-height: 1.3;
        }
    }
}
.land-africa-0-right{
    width: 55%;
    max-width: 533px;
    min-height: auto;
    background-color: #ffffff;
    height: auto;
    padding: 30px;
    border: 1px solid #E8EDF5;
    border-radius: 20px;

    @media (max-width: 991px){
        padding: 20px;
    }
    @media (max-width: 767px){
        width: 100%;
        max-width: none;
        border: none;
        padding: 0;
        margin-top: 40px;
    }

    .register-form h1{

        @media (min-width: 768px) and (max-width: 991px){
            font-size: 22px;
            margin-bottom: 15px;
        }
    }
}
.ul-check-2{
    list-style: none;
    padding: 0;
    margin: 0 0 36px;

    li{
        background-position: 4px 8px;
        padding-bottom: 17px;
        padding-left: 32px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M3.43656 12.2203C2.78594 11.4894 2.26038 10.7744 1.7527 10.0819C1.28879 9.45229 0.886552 8.77947 0.551532 8.07278C0.341845 7.64399 0.249032 7.2098 0.703229 6.93064C2.00491 6.1307 2.27166 6.89451 2.97916 7.78076C3.39854 8.30591 4.01694 9.17189 4.40442 9.71903C4.77454 10.2415 5.22574 9.29849 5.40587 9.03239C6.04607 8.0867 7.70999 5.77148 8.39759 4.91568C9.05037 4.10326 11.1574 1.82729 11.5502 1.46529C11.8845 1.15705 12.5904 0.480958 13.0651 0.853927C13.5639 1.2458 13.8076 1.99071 13.4569 2.47505C12.8463 3.31827 11.8871 4.12041 11.2235 4.92331C9.88001 6.54856 8.6081 8.30044 7.38721 10.0448C6.97234 10.6375 6.38985 11.6303 6.02823 12.2732C5.35176 13.4758 4.75759 13.7046 3.43656 12.2203Z' fill='%23FFD226'/%3E%3C/svg%3E");
    }
}

.land-africa-3{
    background-color: rgb(38 59 127 / 5%);
    padding: 70px 12.2vw;

    @media (max-width: 1500px){
        padding: 60px 7.2vw;
    }
    @media (max-width: 1199px){
        padding: 50px 20px;
    }
}
.land-africa-3-container{

    h2{
        max-width: 700px;
        margin: 0 auto 20px;
        font-weight: 700;
        font-size: 36px;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
    p{
        margin-bottom: 40px;
        color: #5D5F63;
        font-size: 18px;
    }
    .ul-check{
        max-width: none;
        flex-grow: 1;

        @media (max-width: 767px){
            margin-bottom: 30px;
            padding-right: 0;
        }
        li{
            padding: 0 0 12px 30px;
        }
    }
}
.land-africa-3-item{
    max-width: 1000px;
    margin: 0 auto;

    @media (max-width: 767px){
        flex-direction: column;
    }
}
.africa-text-block{
    text-align: center;
    padding: 96px 20px;

    @media (max-width: 767px){
        padding: 50px 20px;
    }
    h2{
        font-weight: 400;
        font-size: 25px;
        line-height: 38px;

        @media (max-width: 991px){
            font-size: 22px;
            line-height: 32px;
        }
        @media (max-width: 767px){
            font-size: 20px;

            br{
                display: none;
            }
        }
    }
}
.home-slider-section{
    background-color: #172758;
    color: #ffffff;
    padding: 53px 105px 491px 12.2vw;
    border-radius: 0 144px 144px 0;
    width: 100%;
    max-width: 1784px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1500px){
        padding: 73px 105px 438px 7.2vw;
    }
    @media (max-width: 1199px) {
        padding: 73px 50px 438px 25px;
    }
    @media (max-width: 767px){
        padding: 40px 50px 410px 20px;
    }
    &:lang(hy){

        @media (min-width: 768px) and (max-width: 991px){
            padding: 45px 50px 410px 25px;
        }
    }
    > div{
        max-width: 771px;
        width: calc(100% - 265px);

        @media (max-width: 767px){
            width: 100%;
            margin-bottom: 30px;
        }
        &:lang(hy){

            @media (min-width: 768px) and (max-width: 991px){
                width: calc(100% - 220px);
            }
        }
        h2{
            font-size: 30px;
            letter-spacing: .9px;
            line-height: 1.55;
            margin-bottom: 24px;

            @media (max-width: 1500px) {
                font-size: 25px;
            }
            @media (max-width: 767px){
                font-size: 20px;
                letter-spacing: 0.3px;
                line-height: 1.3;
                margin-bottom: 20px;
            }
            &:lang(hy){

                @media (min-width: 768px) and (max-width: 991px){
                    font-size: 22px;
                }
            }
        }
        p{
            font-size: 20px;
            color: #BBBBBB;
            letter-spacing: .6px;
            margin-bottom: 0;

            @media (max-width: 1500px) {
                font-size: 18px;
            }
            @media (max-width: 767px){
                font-size: 16px;
            }
            &:lang(hy){

                @media (min-width: 768px) and (max-width: 991px){
                    font-size: 16px;
                }
            }
        }
    }
    .btn{
        margin-top: auto;
        margin-left: auto;
        font-size: 20px;
        padding: 0.65rem 1.5rem;
        will-change: transform;
        box-shadow: 0 0 0 0 rgb(255 210 38);
        animation: pulse 1.5s infinite;

        &:hover{
            animation: none;
        }

        @media (max-width: 1500px){
            font-size: 16px;
        }
        @media (max-width: 767px){
            margin: 0 auto;
        }
    }
}
@keyframes pulse {
    0% {
        transform: scale(.9)
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(#5a99d4, 0);
    }
    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
}
.btn-pulse-comp{
    will-change: transform;
    box-shadow: 0 0 0 0 rgb(255 210 38);
    animation: pulse 1.5s infinite;

    &:hover{
        animation-duration: 5s;
    }
}
.theme-slider {
    padding-left: 12.2vw;
    margin-top: -420px;
    padding-bottom: 57px;

    @media (max-width: 1500px){
        padding-left: 7.2vw;
        margin-top: -342px;
    }
    @media (max-width: 1199px){
        padding-left: 25px;
    }
    @media (max-width: 767px){
        padding-left: 15px;
    }
    .slick-slide{
        padding: 0 20px 0 0;

        @media (max-width: 1500px){
            padding: 0 12px 0 0;
        }
    }
    .slick-prev, .slick-next{
        top: inherit;
        bottom: 0;
        width: 51px;
        height: 38px;

        @media (max-width: 767px){
            display: none!important;
        }
    }
    .slick-next:before, .slick-prev:before{
        border-color: #172758;
        border-width: 0 1px 1px 0;
        padding: 5.6px;
        margin: 0;
    }
    .slick-prev{
        left: inherit;
        right: 132px;

        &:after{
            content: "";
            position: absolute;
            left: 18px;
            top: 19px;
            height: 1px;
            width: 25px;
            background-color: #172758;
        }
    }
    .slick-next{
        right: 50px;

        &:after{
            content: "";
            position: absolute;
            right: 35px;
            top: 19px;
            height: 1px;
            width: 25px;
            background-color: #172758;
        }
    }
    picture{
        height: 466px;
        width: 393px;
        overflow: hidden;
        display: block;
        border-radius: 10px;

        @media (max-width: 1500px) {
            width: 308px;
            height: 365px;
        }
        img{
            width: 100%;
            height: auto;
            border-radius: 10px;
            animation:  10s infinite alternate linear;
        }
        .theme-slide-1, .theme-slide-2, .theme-slide-3, .theme-slide-5, .theme-slide-8, .theme-slide-9{

            &:hover{
                animation-name: slide-1;
            }
        }
        .theme-slide-4{

            &:hover{
                animation-name: slide-4;
            }
        }
        .theme-slide-6{

            &:hover{
                animation-name: slide-6;
            }
        }
        .theme-slide-7{

            &:hover{
                animation-name: slide-7;
            }
        }
    }
    p{
        color: #111F4A;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: .5px;
        padding: 25px 0 0 28px;

        @media (max-width: 1500px){
            font-size: 15px;
        }
        @media (max-width: 767px){
            padding: 10px 0 0 28px;
        }
    }
}
@keyframes slide-1 {
    0%{
        transform: translateY(0px);
    }
    2%{
        transform: translateY(0px);
    }
    98%{
        transform: translateY(-867px);
    }
    100%{
        transform: translateY(-867px);
    }
}
@media (max-width: 1500px){
    @keyframes slide-1 {
        0%{
            transform: translateY(0px);
        }
        2%{
            transform: translateY(0px);
        }
        98%{
            transform: translateY(-642px);
        }
        100%{
            transform: translateY(-642px);
        }
    }
}

@keyframes slide-4 {
    0%{
        transform: translateY(0px);
    }
    2%{
        transform: translateY(0px);
    }
    98%{
        transform: translateY(-1040px);
    }
    100%{
        transform: translateY(-1040px);
    }
}
@media (max-width: 1500px){
    @keyframes slide-4 {
        0%{
            transform: translateY(0px);
        }
        2%{
            transform: translateY(0px);
        }
        98%{
            transform: translateY(-814px);
        }
        100%{
            transform: translateY(-814px);
        }
    }
}

@keyframes slide-6 {
    0%{
        transform: translateY(0px);
    }
    2%{
        transform: translateY(0px);
    }
    98%{
        transform: translateY(-1051px);
    }
    100%{
        transform: translateY(-1051px);
    }
}
@media (max-width: 1500px){
    @keyframes slide-6 {
        0%{
            transform: translateY(0px);
        }
        2%{
            transform: translateY(0px);
        }
        98%{
            transform: translateY(-822px);
        }
        100%{
            transform: translateY(-822px);
        }
    }
}

@keyframes slide-7 {
    0%{
        transform: translateY(0px);
    }
    2%{
        transform: translateY(0px);
    }
    98%{
        transform: translateY(-789px);
    }
    100%{
        transform: translateY(-789px);
    }
}
@media (max-width: 1500px){
    @keyframes slide-7 {
        0%{
            transform: translateY(0px);
        }
        2%{
            transform: translateY(0px);
        }
        98%{
            transform: translateY(-582px);
        }
        100%{
            transform: translateY(-582px);
        }
    }
}



.land-africa-4{
    padding: 64px 20px 64px 12.2vw;
    margin-left: auto;
    height: 44.62vw;
    margin-bottom: 50px;
    margin-top: 80px;

    @media (max-width: 1500px){
        padding-left: 7.2vw;
    }
    @media (max-width: 1199px){
        padding: 30px 20px 30px 25px;
        height: 51vw;
    }
    @media (max-width: 767px){
        height: inherit;
        margin-bottom: 40px;
        margin-top: 0;
        padding: 3px 20px 10px 25px;
    }
}
.land-africa-4-left{
    width: 42%;

    @media (max-width: 1199px){
        width: 44%;
    }
    @media (max-width: 767px){
        width: 100%;
    }
    h2{
        font-weight: 600;
        font-size: 30px;
        line-height: 150%;
        margin-bottom: 30px;

        @media (max-width: 1199px){
            font-size: 20px;
            line-height: 140%;
            margin-bottom: 20px;
        }
    }
    p{
        color: #5D5F63;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 50px;

        @media (max-width: 1199px){
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 25px;
        }
    }
    .ul-check{
        font-size: 20px;
        max-width: inherit;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1199px){
            font-size: 18px;
        }
        li{
            position: static;
            padding-left: 0;
            cursor: pointer;
            padding-bottom: 21px;
            transition: all .1s;

            @media (max-width: 1199px){
                padding-bottom: 11px;
            }
            svg{
                margin-right: 15px;

                @media (max-width: 1199px){
                    margin-right: 6px;
                }
            }
            span{
                position: absolute;
                right: 0;
                width: 52.6%;
                height: 100%;
                display: block;
                padding: 26px 52px;
                background-color: #172758;
                border-radius: 28px 0 0 28px;
                top: 0;
                cursor: default;

                @media (max-width: 1199px){
                    padding: 25px 26px;
                }
                @media (max-width: 767px){
                    display: none;
                }
                img{
                    width: 100%;
                    height: 100%;
                }

            }
            &:after, &:before{
                display: none;
            }
            &:nth-child(1){
                @media (min-width: 768px) {
                    font-weight: 700;
                }

                span {
                    z-index: 3;

                    img{
                        transform: scale(1);
                    }
                }
            }

            &:hover{
                @media (min-width: 768px) {
                    font-weight: 700;
                }

                &:nth-child(2){
                    span {
                        z-index: 3;
                    }
                }
                &:nth-child(3){

                    span {
                        z-index: 3;
                    }
                }
            }
        }
    }
}
.land-africa-5{
    padding: 20px;
    max-width: 1145px;
    width: 100%;
    margin: 0 auto 50px;

    @media (max-width: 767px){
        margin-bottom: 30px;
    }
    h2{
        font-weight: 600;
        font-size: 30px;
        line-height: 150%;
        margin-bottom: 72px;

        @media (max-width: 991px){
            font-size: 22px;
            margin-bottom: 50px;
        }
        @media (max-width: 767px){
            margin-bottom: 30px;
        }
    }
}
.land-africa-5-container{
    margin-left: -15px;
    margin-right: -15px;
}
.land-africa-5-item{
    width: 25%;
    padding: 0 15px;

    @media (max-width: 767px){
        width: 50%;
        margin-bottom: 40px;
    }
    h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;

        @media (max-width: 991px){
            font-size: 16px;
        }
    }
}
.title-p {
    font-size: 20px;

    @media (max-width: 991px) {
        font-size: 18px;
    }
    @media (max-width: 767px) {
        font-size: 17px;
    }
}
.features-4 {
    padding: 73px 4vw 65px;
    background-color: #FBFBFD;

    .btn {
        margin-top: 45px;
    }

    @media (max-width: 767px) {
        padding: 40px 20px 40px;
    }

    .title-p {
        margin-bottom: 66px;

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
}

.features-4-container {
    width: 100%;
    max-width: 1023px;
    margin: 0 auto;

    @media (max-width: 767px) {
        max-width: 430px;
    }
}

.features-4-right {
    width: 45%;
    max-width: 290px;
    padding-left: 20px;

    @media (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }

    .ul-check {
        max-width: none;
        padding-right: 0;
        margin-bottom: 0;
    }

    .btn {
        font-size: 20px;
        padding: 0.65rem 1.4rem;

        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
}

.features-4-left {
    width: 55%;
    max-width: 537px;

    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 15px;
    }

    img {
        width: 100%;
    }
}

.land-africa-7{
    margin: 60px auto 30px;
    max-width: 1230px;
    padding: 0 3px;

    .title-h2{
        margin-bottom: 74px;
        padding: 0 20px;
    }
}
.land-africa-7-item{
    padding: 0 14px;
    margin-bottom: 72px;

    @media (max-width: 991px){
        margin-bottom: 50px;
    }
    @media (max-width: 550px){
        width: 50%;
        margin-bottom: 30px;
    }
    h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 0;

        @media (max-width: 991px){
            font-size: 16px;
        }
        @media (max-width: 550px){
            font-size: 15px;
            line-height: 1.2;
        }
    }
    img{
        margin-right: 15px;

        @media (max-width: 550px){
            margin-right: 8px;
            width: 40px;
            height: 40px;
        }
    }
}

.home-customers{
    color: #ffffff;
    padding: 80px 12.2vw 15px;
    margin-bottom: 50px;
    position: relative;

    &:after{
        content: "";
        background-color: #172758;
        border-radius: 0 144px 0 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 64%;

        @media (max-width: 1500px){
            height: 32vw;
        }
        @media (max-width: 1199px){
            height: 34vw;
        }
        @media (max-width: 991px){
            height: 45vw;
        }
        @media (max-width: 767px){
            height: 100%;
        }
    }
    @media (max-width: 1500px){
        padding: 70px 7.2vw 15px;
    }
    @media (max-width: 1199px){
        padding: 50px 20px 15px;
    }
    @media (max-width: 767px){
        padding: 50px 20px 20px;
        margin-bottom: 0;
    }
    > h2{
        font-size: 30px;
        letter-spacing: 0.7px;
        line-height: 1.55;
        margin-bottom: 11px;
        padding-right: 20px;

        @media (max-width: 1500px){
            font-size: 25px;
        }
        @media (max-width: 767px){
            font-size: 20px;
            letter-spacing: 0.3px;
            line-height: 1.3;
        }
    }
    > h2 + p{
        color: #BBBBBB;
        letter-spacing: 0.6px;
        margin-bottom: 0;
        font-size: 20px;

        @media (max-width: 1500px){
            font-size: 18px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}
.home-customers-section{
    margin: 57px -8px 30px;
    position: relative;
    z-index: 1;

    @media (max-width: 767px){
        margin: 45px 0 10px;

        + .text-right{

            a{
                color: #ffffff;
            }
        }
    }
}
.home-customers-block{
    padding: 0 8px;
    width: 33.33%;

    @media (max-width: 767px){
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
    }
}
.home-customers-item{
    width: 100%;
    height: 100%;
    color: #1D1D1D;
    display: block;

    &:hover{
        text-decoration: none;
        color: #000000;
    }

    > img{
        height: 12.42vw;
        display: block;
        border-radius: 30px 30px 0 0;
        width: 100%;
        object-fit: cover;

        @media (max-width: 1500px){
            height: 14vw;
        }
        @media (max-width: 1199px){
            height: 16vw;
        }
        @media (max-width: 767px){
            height: 45vw;
            border-radius: 10px 10px 0 0;
        }
    }
}
.home-customers-item-info{
    background-color: #ffffff;
    padding: 15px 20px;
    border-radius: 0 0 10px 10px;
    height: calc(100% - 12.42vw);
    display: flex;
    flex-direction: column;

    @media (max-width: 1500px){
        height: calc(100% - 14vw);
    }
    @media (max-width: 1199px){
        height: calc(100% - 16vw);
    }
    @media (max-width: 767px){
        height: calc(100% - 45vw);
    }
    p{
        letter-spacing: .5px;
        line-height: 1.75;

        @media (max-width: 991px){
            font-size: 14px;
            line-height: 1.5;
        }
    }
}
.home-customers-item-info-img{
    display: flex;
    align-items: center;
    margin-top: auto;

    img{
        border: solid 1px #C9C9C9;
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;

        @media (max-width: 991px){
            width: 40px;
            height: 40px;
            margin-right: 7px;
        }
    }
    b{

        @media (max-width: 991px){
            font-size: 15px;
            line-height: 1.2;
        }
    }
}

.truste-pilot{
    padding: 0 20px;

    @media (max-width: 767px){
        padding-right: 0;
        margin-bottom: 0;
    }
}
.truste-pilot-container{
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    @media (max-width: 1199px){
        max-width: 728px;
    }
    @media (max-width: 767px){

        .d-flex.flex-wrap{
            flex-wrap: nowrap!important;
            overflow: hidden;
            overflow-x: auto;
        }
    }
}
.tp-widget-review{
    width: 235px;
    padding: 0 15px;
    margin: 20px 0;
    color: #1f2936;
    font-family: "Helvetica Neue","Helvetica","Arial","sans-serif";

    @media (min-width: 768px) and (max-width: 1199px){
        width: 273px;
    }
    &:hover{
        color: #1f2936;
        text-decoration: none;
    }
}
.tp-widget-review-head{
    display: flex;
    align-items: center;
    margin-bottom: 9px;

    > svg{
        margin-right: 7px;
    }
    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
    }
}
.tp-widget-text-1{
    color: #1f2936;
    font-size: 14px;
    font-weight: 700;
    height: 16px;
    margin: 0 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tp-widget-text-2{
    color: #191919;
    font-size: 13px;
    line-height: 16px;
    max-height: 50px;
    margin-bottom: 0;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas, .tp-stars--5 .tp-star:nth-of-type(-n+5) .tp-star__canvas--half {
    fill: #00b67a;
}
.tp-widget-review-left{
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    width: 140px;
    flex: 0 0 140px;
    text-align: center;
    margin-right: 40px;
    color: black;
    text-decoration: none;

    @media (max-width: 767px) {
        width: 100px;
        flex: 0 0 100px;
        margin-right: 15px;
    }
    &:hover{
        color: black;
        text-decoration: none;
    }
}

.tp-widget-humanscore {
    font-size: 20px;
    margin: 0 0 5px;
}
.tp-widget-businessinfo {
    font-size: 11px;
    line-height: 16px;
    margin: 11px 0 13px;
}


.capterat-container{
    max-width: 1120px;
    display: flex;
    align-items: center;
    margin: 40px auto 0;

    @media (max-width: 1199px){
        max-width: 728px;
    }
    @media (max-width: 767px){
        margin-top: 10px;

        .d-flex.flex-wrap{
            flex-wrap: nowrap!important;
            overflow: hidden;
            overflow-x: auto;
        }
    }
}
.captera-item-head{
    width: 140px;
    flex: 0 0 140px;
    margin-right: 40px;

    @media (max-width: 767px) {
        width: 100px;
        flex: 0 0 100px;
        margin-right: 15px;
    }
}
.captera-item{
    width: 235px;
    padding: 0 15px;
    color: #1f2936;
    margin: 20px 0;
    font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";

    @media (min-width: 768px) and (max-width: 1199px){
        width: 273px;
    }
}


.get-answers {
    max-width: 1000px;
    margin: 0 auto;
    padding: 101px 20px;

    @media (max-width: 767px) {
        padding: 60px 20px;
    }

    h2 {
        margin-bottom: 1.9em;
        font-size: 35px;
        font-weight: 600;
        letter-spacing: .9px;

        @media (max-width: 991px) {
            font-size: 1.5rem;
        }
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
    .btn{
        font-size: 20px;
        padding: 0.65rem 1.45rem;
        will-change: transform;
        box-shadow: 0 0 0 0 rgb(255 210 38);
        animation: pulse 1.5s infinite;

        &:hover{
            animation: none;
        }
    }
}

.africa-footer{
    background: radial-gradient(100% 177.14% at 100% 100%, #244199 0%, #244199 0.01%, #16285F 28.23%, #111F4A 100%);
    color: #ffffff;
    padding: 63px 20px 70px;
}
.africa-footer-container{
    max-width: 830px;
    margin: 0 auto;

    h2{
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 60px;
    }
    h3{
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        max-width: 488px;
        margin: 0 auto;

        @media (max-width: 575px){
            font-size: 18px;
        }
    }
    address{
        display: flex;
        margin-bottom: 76px;

        @media (max-width: 575px){
            flex-direction: column;
        }
    }
    form{
        max-width: 440px;
        margin: 40px auto 50px;

        .form-group {
            margin-bottom: 10px;
        }
        .form-control{
            background-color: transparent;
            border: 0.5px solid rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            color: #ABABAB;
            font-size: 14px;
            height: 40px;
        }
        textarea.form-control{
            height: 111px;
            resize: none;
        }
    }
}
.africa-footer-item{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 0 15px;
    width: 33.33%;

    @media (max-width: 575px){
        width: 100%;
        border: none;
        margin-bottom: 20px;
    }
    &:nth-child(2){
        border: solid 1px rgba(70, 83, 122, 0.5);
        border-top: none;
        border-bottom: none;

        @media (max-width: 575px){
            border: none;
        }
    }
    img{
        margin-bottom: 18px;
        width: 25px;
        height: 25px;
    }
    p{
        margin-bottom: 8px;
    }
    a, span{
        color: #ABABAB;
    }
}

.comparison{
    padding: 110px 20px 20px;
    margin: 0 auto;
    max-width: 1150px;

    @media (max-width: 991px){
        padding: 80px 15px 20px;
    }
    h1{
        font-weight: 700;
        font-size: 25px;
        margin: 10px 0 40px;

        @media (max-width: 767px){
            font-size: 22px;
            margin-bottom: 20px;
        }
    }
    .table{
        margin-bottom: 60px;
        table-layout: fixed;
        margin-top: 20px;

        @media (max-width: 767px){
            margin-bottom: 40px;
            margin-top: 10px;
        }
        td{
            border: none;
            padding: 8px 10px;
            font-size: 18px;
            color: #363636;

            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
                padding-left: 20px;
            }
            @media (max-width: 767px){
                width: 220px;
                font-size: 14px;
                padding: 4px 10px;
            }
        }
    }
}
.comparison-select-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    position: sticky;
    top: 55px;
    padding: 10px 0;
    z-index: 1;
    background-color: #ffffff;
    margin-bottom: 30px;

    @media (max-width: 1199px){
        top: 50px;
    }
    @media (max-width: 767px){
        position: static;
        margin-left: -8px;
        margin-right: -8px;
    }
}
.has-header-notification{

    .comparison-select-container{
        top: 115px;

        @media (max-width: 991px){
            top: 110px;
        }
    }
}
.comparison-content{

    @media (max-width: 767px) {
        overflow: hidden;
        overflow-x: auto;
    }
}
.comparison-select{
    padding: 0 15px;
    width: 33.33%;
    flex-grow: 1;
    max-width: 567px;
    min-width: 220px;

    @media (max-width: 767px){
        width: 230px;
        flex: 0 0 230px;
        padding: 0 8px;
    }
    .vs__dropdown-toggle{
        height: 60px;
        padding: 0;
    }
    .vs__selected{
        margin: 0;
    }
    .vs--single {

        &.vs--open .vs__selected,
        &.vs--loading .vs__selected {
            position: static;
            opacity: 0.8;
        }
    }
    img{
        max-height: 40px;
        max-width: 90%;
        width: auto;
    }
}
.comparison-select-uteach{
    height: 60px;
    border: 1px solid rgba(60,60,60,0.26);;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
}
.comparison-h2{
    font-weight: 600;
    color: #050505;
    font-size: 25px;
    border-bottom: 1px solid #D9D3D3;
    padding-bottom: 16px;
    position: sticky;
    top: 135px;
    background-color: #fff;

    @media (max-width: 1199px){
        top: 128px;
    }
    @media (max-width: 767px){
        font-size: 20px;
        padding-bottom: 10px;
        top: 0;
        left: 0;
    }
}
.has-header-notification{

    @media (min-width: 768px) {
        .comparison-h2{
            position: static;
        }
    }

}

.loader {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #0000007a;
    z-index: 100000;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;

    .spinner-border {
        width: 3.5rem;
        height: 3.5rem;
    }

    &.show {
        display: flex;
    }
}

.compare-page {

    .page-link:focus, .page-item.active .page-link {
        z-index: 2;
    }
    .compare-search .btn-blue {
        padding: 0.2rem 0.5rem 0.35rem 0.5rem;
        border-radius: 0 0.25rem 0.25rem 0;
    }
    .compare-search .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .pair-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 767px){
            flex-direction: column;
        }
    }
    .pair-container-column {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;

        a {
            width: fit-content;
        }
    }
}

.guide-page{
    padding: 130px 0 40px;
    background-color: #FBFBFD;

    @media (max-width: 991px){
        padding: 100px 0 40px;
    }
    @media (max-width: 767px){
        padding: 80px 0 40px;
    }
    .pagination {
        display: flex;
        justify-content: center;
        list-style: none;
        width: 100%;
        padding: 0;

        .page-item {
            margin: 0 5px;

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }
        }

        .page-link {
            font-weight: bold;
            padding: 6px 7px;
            display: block;
            text-align: center;
            background: #ffffff;
            color: #244199;
            border-radius: 25px;
            width: 35px;
            height: 35px;
            border: none;
            line-height: 1.5;
            margin-left: 0;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);

            @media (max-width: 767px) {
                padding: 4px;
                width: 25px;
                height: 25px;
                font-size: 13px;
            }

            &:hover {
                text-decoration: none;
                background: #FFD226;
                color: #ffffff;
                box-shadow: none;
            }
        }
    }

    .pagination .page-item.active .page-link {
        background: #244199;
        color: #ffffff;
        box-shadow: none;
    }
}
.guide-container{
    max-width: 1150px;
    padding: 0 20px;
    margin: 0 auto;

    h1{
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 40px;

        @media (max-width: 767px){
            font-size: 22px;
            margin-bottom: 25px;
        }
    }
}
.guide-search{

    .guide-search-wrapper{
        max-width: 580px;
        margin: 0 auto 40px;
    }
}
.guide-search-group{
    height: 50px;

    .form-control{
        height: 100%;
        border: 1px solid #E4E4E4;
        border-radius: 6px 0 0 6px;
    }
    .btn{
        background-color: $color-2;
        height: 100%;
        border-radius: 0 6px 6px 0;
    }
}

.guide-content{
    margin-left: -15px;
    margin-right: -15px;
}
.guide-item-head{
    width: 100%;
    padding: 0 15px 25px 15px;

    @media (max-width: 767px){
        padding: 0 15px 15px 15px;
    }
    > img{
        border-radius: 14px 14px 0 0;
        height: 391px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 767px){
            height: 76vw;
        }
    }
}
.guide-item-head-info{
    background: #F5F5F5;
    border: 1px solid #E2E2E2;
    border-radius: 0 0 14px 14px;
    padding: 25px 24px 25px 50px;
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
        padding: 20px 20px 20px 20px;
    }
    @media (max-width: 767px){
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 15px;
    }
    p{
        font-size: 25px;
        margin-bottom: 0;
        margin-right: auto;
        padding-right: 20px;

        @media (max-width: 991px){
            font-size: 22px;
        }
        @media (max-width: 767px){
            width: 100%;
            font-size: 18px;
            padding-right: 0;
            margin-bottom: 20px;
        }
    }
    .link{
        color: #000000;
        padding: 5px;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        white-space: nowrap;

        @media (max-width: 767px){
            padding-left: 0;
        }
        &:hover{
            text-shadow: 0 0 0;
        }
    }
    .btn{
        color: #244199;
        padding: 5px 0 5px 5px;
        margin-left: 40px;
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        white-space: nowrap;

        @media (max-width: 991px){
            margin-left: 20px;
        }
        &:focus{
            box-shadow: none;
        }
        &:hover{
            text-shadow: 0 0 0;
        }
        svg{
            margin-right: 7px;
        }
    }
}

.guide-item{
    width: 33.33%;
    padding: 15px;

    @media (max-width: 767px){
        width: 100%;
    }
    > img{
        border-radius: 14px 14px 0 0;
        height: 310px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 991px){
            height: 190px;
        }
        @media (max-width: 767px){
            height: 76vw;
        }
    }
}
.guide-item-info{
    background: #F5F5F5;
    border: 1px solid #E2E2E2;
    border-radius: 0 0 14px 14px;
    padding: 14px 20px 14px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px){
        padding: 12px 15px 12px 15px;
    }
    p{
        font-size: 25px;
        margin-right: auto;
        width: 100%;
        height: 116px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 38px;
        margin-bottom: 7px;

        @media (max-width: 991px){
            font-size: 18px;
            line-height: 24px;
            height: 76px;
        }
        @media (max-width: 767px){
            margin-bottom: 20px;
            height: auto;
            display: block;
        }
    }
    .link{
        color: #000000;
        padding: 5px 5px 5px 0;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;

        &:hover{
            text-shadow: 0 0 0;
        }
        @media (max-width: 991px){
            font-size: 14px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
    .btn{
        color: #244199;
        padding: 5px 0 5px 5px;
        display: inline-flex;
        align-items: center;
        font-weight: 500;

        @media (max-width: 991px){
            font-size: 14px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
        &:focus{
            box-shadow: none;
        }
        &:hover{
            text-shadow: 0 0 0;
        }
        svg{
            margin-right: 7px;
        }
    }
}

.guide-modal{
    color: black;

    .modal-content{
        border-radius: 14px;
    }
    .modal-header .close{
        font-weight: 300;
        font-size: 36px;
    }
}
.modal-guide-search{
    max-width: 620px;
    margin: 0 auto 60px;

    h2{
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 30px;
    }
}
.modal-guide-search-group{
    height: 50px;

    @media (max-width: 460px){
        height: auto;
        flex-direction: column;
    }
    .form-control{
        height: 100%;
        border: 1px solid #E4E4E4;
        border-radius: 6px 0 0 6px;
        padding: 0.375rem 1rem;

        @media (max-width: 460px){
            border-radius: 6px;
            margin-bottom: 15px;
            height: 45px;
        }
    }
    .btn{
        background-color: $color-2;
        height: 100%;
        border-radius: 0 6px 6px 0;
        color: #ffffff;
        white-space: nowrap;
        width: 215px;
        flex: 0 0 215px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;

        @media (max-width: 991px) {
            width: auto;
            flex: 0 0 auto;
        }
        @media (max-width: 460px){
            border-radius: 6px;
            height: 45px;
        }
        svg{
            margin-right: 7px;
        }
    }
}

.guide-show{
    margin-bottom: 40px;
    color: black;

    @media (max-width: 767px){
        flex-direction: column;
    }
}
.guide-show-left{
    width: 350px;
    flex: 0 0 350px;

    @media (max-width: 991px){
        width: 300px;
        flex: 0 0 300px;
    }
    @media (max-width: 767px){
        width: 100%;
        flex: 0 0 auto;
        margin-bottom: 20px;
    }
    img{
        width: 100%;
        border-radius: 14px;
    }
}
.guide-show-right{
    flex-grow: 1;
    font-size: 18px;
    padding-left: 46px;

    @media (max-width: 991px){
        padding-left: 25px;
        font-size: 16px;
    }
    @media (max-width: 767px){
        padding-left: 0;
    }
    > h1{
        margin-bottom: 22px;
    }
}
.guide-show-search{
    margin-top: 60px;

    > p{
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 30px;
    }
}
.guide-show-search-group{
    height: 50px;

    @media (max-width: 460px){
        height: auto;
        flex-direction: column;
    }
    .form-control{
        height: 100%;
        border: 1px solid #E4E4E4;
        border-radius: 6px 0 0 6px;
        padding: 0.375rem 1rem;

        @media (max-width: 460px){
            border-radius: 6px;
            margin-bottom: 15px;
            height: 45px;
        }
    }
    .btn{
        background-color: $color-2;
        height: 100%;
        border-radius: 0 6px 6px 0;
        color: #ffffff;
        white-space: nowrap;
        width: 215px;
        flex: 0 0 215px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;

        @media (max-width: 991px) {
            width: auto;
            flex: 0 0 auto;
        }
        @media (max-width: 460px){
            border-radius: 6px;
            height: 45px;
        }
        svg{
            margin-right: 7px;
        }
    }
}

.user-nav-buttons-container{
    width: 300px;
    justify-content: flex-end;

    &:lang(hy){
        width: 330px;

        @media (max-width: 1199px){
            width: auto;
        }
    }
    &:lang(ru){
        width: 400px;

        @media (max-width: 1199px){
            width: auto;
        }
    }
    @media (max-width: 1199px){
        flex-direction: column !important;
        align-items: stretch !important;
        padding: 0 24px 30px;
        width: auto;
    }
}

.btn-julia{
    background-color: #244199;
    border-color: #244199;
    color: #ffffff;
    padding: 7px 20px;
    border-radius: 6px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    font-weight: 300;

    &:hover{
        color: #244199;
        background-color: #ffffff;
        text-decoration: none;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
    &:lang(hy){

        @media (min-width: 1200px) and (max-width: 1299px){
            font-size: 15px;
        }
    }

    @media (max-width: 1199px){
        margin: 0 0 30px 24px;
        width: 232px;
    }
    .btn-pulse2{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-flex;
        margin-right: 10px;
        box-shadow: 0 0 0 0 #ffffff;
        animation: pulse2 1.5s infinite;
    }
}

@keyframes pulse2 {
    50% {
        box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px rgb(255 255 255 / 75%);
    }
    100% {
        box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px rgb(255 255 255 / 0%);
    }
}

.santa-2023{
    position: fixed;
    left: 20px;
    bottom: 150%;
    z-index: 9;
    width: 14%;
    max-width: 257px;
    animation: santa 4s 1s forwards;

    @media (max-width: 767px) {
        width: 30%;
        right: 20px;
    }
    a{
        display: block;
    }
    img{
        width: 100%;
    }
    span{
        position: absolute;
        right: 0;
        top: -35px;
        cursor: pointer;
        padding: 5px;
        z-index: 2;

        svg{
            filter: brightness(0.7);
        }
    }
    &.santa-hide{

        animation: santa-hide 1s forwards;
    }
}

@keyframes  santa {
    0% {
        bottom: 150%;
    }
    100%{
        bottom: 1%;
    }
}
@keyframes  santa-hide {
    0% {
        bottom: 1%;
    }
    100%{
        bottom: -100%;
    }
}


.support-search-box{
    position: absolute;
    display: flex;
    z-index: 2;
    background-color: #ffffff;
    border-radius: 4px;
    max-height: 720px;
    width: 735px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 8%), 0 0 4px rgb(0 0 0 / 12%);
    top: 50px;
    overflow: hidden;
    left: -120px;

    @media (max-width: 767px) {
        left: 0;
        width: 100%;
        flex-direction: column;
        border-radius: 6px;
    }
}
.support-search-box-left{
    flex: 0 0 210px;
    width: 210px;

    @media (max-width: 767px){
        flex: 0 0 100%;
        width: 100%;
    }
}
.support-search-box-left-block{
    background-color: #ffffff;
    border-radius: 4px 0 0 4px;
    align-items: flex-start;
    width: 100%;
    padding: 11px 10px;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px){
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;

        &::-webkit-scrollbar{
            display: none;
        }
    }
}
.support-search-box-left-btn{
    padding: 10px 10px 10px 10px;
    color: #192435;
    border: none;
    width: 100%;
    height: 45px;
    text-align: left;
    border-radius: 4px;
    white-space: nowrap;
    margin-bottom: 5px;
    background-color: transparent;
    display: flex;
    align-items: center;

    &.active, &:hover{
        background-color: #2441991f;
        text-shadow: 0 0 0;

        @media (max-width: 767px){
            background-color: transparent;
            border-color: $color-2;
            color: $color-2;
        }
    }
    &:hover{
        text-decoration: none;
        color: #000000;

        @media (max-width: 767px){
            color: $color-2;
        }
    }
    @media (max-width: 767px){
        width: auto;
        color: $color-2;
        font-weight: 500;
        font-size: 12px;
        height: 36px;
        margin: 0 2px;
        border-radius: 0;
        border-bottom: solid 2px transparent;
    }
    img, svg{
        margin-right: 7px;
        width: 24px;
        height: 24px;
        object-fit: contain;
        flex: 0 0 24px;

        @media (max-width: 767px){
            display: none;
        }
    }
    span{
        text-overflow: ellipsis;
        overflow: hidden;
    }
}


.support-search-box-right{
    padding: 22px 10px;
    height: 100%;
    max-height: calc(100vh - 250px);
    box-sizing: border-box;
    overflow-y: auto;
    flex-grow: 1;
    min-height: 375px;

    @media (hover: hover){
        &::-webkit-scrollbar-track{
            -webkit-box-shadow: inset 0 0 16px rgb(0 0 0 / 11%);
            background-color: #F5F5F5;
        }
        &::-webkit-scrollbar{
            width: 12px;
        }
        &::-webkit-scrollbar-thumb{
            -webkit-box-shadow: inset 0 0 3px rgb(0 0 0 / 30%);
            background-color: #b2b2b2;
        }
    }

}
.support-search-box-right-title{
    font-weight: 600;
    font-size: 14px;
}
.support-result-link{
    display: flex;
    align-items: flex-start;
    color: #000000;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    word-break: break-word;

    &:hover{
        text-decoration: none;
        color: #000000;
        background-color: #f7f7fe;
    }
    @media (max-width: 767px){
        background-color: #f8f8fd;
    }
}
.support-result-icon{
    margin-right: 10px;
    width: 22px;
    flex: 0 0 22px;
    height: 22px;

    @media (max-width: 767px){
        margin-right: 8px;
        width: 20px;
        flex: 0 0 20px;
        height: 20px;
    }
    img, svg{
        max-width: 100%;
        max-height: 100%;
    }
}
.support-result-wrapper-title{
    font-size: 14px;
    line-height: 25px;

    @media (max-width: 767px){
        line-height: 20px;
        margin-bottom: 4px;
    }
}
.support-result-wrapper-description{
    font-size: 12px;
    line-height: 22px;
    color: #656e7a;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-height: 44px;
    overflow: hidden;

    @media (max-width: 767px){
        line-height: 19px;
    }
}
.support-result-image{
    margin-left: 10px;

    @media (max-width: 767px){
        max-width: 50px;
        margin-left: 8px;
    }
}
.support-result-link-all{
    font-size: 14px;
    margin-left: 12px;
    margin-top: 12px;
    display: inline-block;
}

.land-comp-head{
    padding: 0 0 0 6vw;
    position: relative;

    @media (max-width: 1199px){
        padding: 0 0 0 4vw;
    }
    @media (max-width: 767px){
        padding: 0 20px 0 20px;
    }
    @media (min-width: 1500px) {
        padding: 0 0 0 12vw;
    }
}
.land-comp-head-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    @media (max-width: 767px){
        height: calc(100% - 229px);
    }
}
.land-comp-head-ru{

    .land-comp-head-bg{
        height: 100%;
    }
    .land-comp-head-left{
        padding: 110px 30px 75px 0;

        @media (max-width: 767px){
            padding: 70px 0 0 0;
        }
    }
}
.land-comp-head-container{
    position: relative;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4vh 0 4vh 0;

    @media (max-width: 767px){
        flex-wrap: wrap;
    }
    > img{
        position: absolute;
        left: 0;
        top: 42px;

        @media (max-width: 767px){
            top: 20px;
        }
    }
}
.land-comp-head-logo{
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 42px;

    @media (max-width: 767px){
        top: 20px;
    }
}
.land-comp-head-left{
    max-width: 850px;
    padding: 60px 30px 60px 0;

    @media (max-width: 1199px){
        max-width: 550px;
    }
    @media (max-width: 767px){
        padding: 60px 0 60px 0;
        width: 100%;
    }
    h1{
        font-weight: 400;
        font-size: 60px;
        margin-bottom: 18px;

        @media (max-width: 1199px) {
            font-size: 40px;
        }
        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 1.4;
        }
        span{

            img{
                margin-right: 20px;

                @media (max-width: 1199px){
                    margin-right: 15px;
                }
            }
            &:first-child{

                img{
                    width: 220px;

                    @media (max-width: 1199px){
                        width: 150px;
                    }
                    @media (max-width: 767px){
                        width: 125px;
                    }
                }
            }
            &:last-child{

                img{
                    margin-top: 16px;

                    @media (max-width: 1199px){
                        width: 190px;
                        margin-top: 10px;
                    }
                    @media (max-width: 767px){
                        width: 140px;
                    }
                }
            }
        }
    }
    p{
        font-size: 27px;
        line-height: 120%;
        font-weight: 200;
        margin-bottom: 20px;

        @media (max-width: 1199px){
            font-size: 24px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
        }
        strong{
            font-weight: 600;
        }
    }
    h2{
        font-weight: 400;
        font-size: 27px;

        @media (max-width: 1199px){
            font-size: 22px;
        }
        @media (max-width: 767px){
            font-size: 20px;
        }
    }
    h3{
        font-weight: 400;
        font-size: 48px;
        margin-bottom: 5vh;

        @media (max-width: 1199px){
            font-size: 35px;
        }
        @media (max-width: 767px){
            font-size: 30px;
            margin-bottom: 30px;
        }
        small{
            font-size: 16px;
        }
    }
    .btn{
        font-size: 20px;
        padding: 0.8rem 2.4rem;
        margin-bottom: 5px;

        @media (max-width: 1199px){
            font-size: 18px;
        }
        @media (max-width: 767px){
            font-size: 16px;
            padding: 0.8rem 1.4rem;
        }
        + br + small{
            font-size: 15px;
            letter-spacing: 0.065em;

            @media (max-width: 1199px){
                font-size: 14px;
            }
        }
    }
    hr{
        border-top: 1px solid rgba(255, 255, 255, 0.12);
        max-width: 438px;
        margin: 4vh 0 4vh 0;

        @media (max-width: 767px){
            margin: 10px 0;
        }
    }
}

.land-comp-head-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 767px){
        flex-direction: row;
        overflow: auto;
        scroll-snap-type: x mandatory;
    }
    img{
        margin: 5px 0;
        transition: transform 0.3s;
        transform-origin: right;
        width: 40vh;
        max-width: 345px;
        min-width: 265px;

        &:hover{
            transform: scale(1.35);

            @media (max-width: 991px){
                transform: none;
            }
        }

        @media (max-width: 1199px){
            max-width: 275px;
        }
        @media (max-width: 767px){
            width: 300px;
            flex: 0 0 300px;
            scroll-snap-align: start;
            margin: 0 15px 0 0;
            min-width: auto;
            max-width: none;
        }
    }
}

.land-comp-2{
    max-width: 1079px;
    padding: 24px;
    margin: 80px auto 0;

    @media (max-width: 767px){
        margin: 20px auto 0;
        padding: 24px 20px;
    }
    > h2{
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 13px;

        @media (max-width: 991px) {
            font-size: 30px;
        }
        @media (max-width: 767px){
            font-size: 26px;
        }
        span{
            color: #244199;
        }
        + p{
            font-size: 25px;

            @media (max-width: 991px) {
                font-size: 20px;
            }
            @media (max-width: 767px){

                span{
                    display: block;
                }
            }
        }
    }
    p{
        font-size: 18px;

        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}
.land-comp-table{
    margin-top: 33px;
    margin-bottom: 33px;

    thead th{
        border: none;
        padding: 1.4rem 4rem;

        @media (max-width: 991px){
            padding: 1rem 0.5rem;

            img{
                width: 110px;
                height: 18px;
                object-fit: contain;
            }
        }
        @media (max-width: 767px){
            padding: 1.4rem 1rem;

            img{
                width: 80px;
                height: 15px;
            }
        }
        &:last-child{
            background: rgba(191, 202, 228, 0.23);
            border-radius: 4px 4px 0 0;
        }
    }

    td{
        text-align: center;
        padding: 0.749rem;
        border-top: 1px solid #BFCAE4;
        vertical-align: middle;

        &:first-child{
            text-align: left;
            padding-left: 0;
            font-size: 18px;

            @media (max-width: 991px){
                font-size: 16px;
            }
            @media (max-width: 767px){
                font-size: 14px;
                padding-right: 0;
            }
        }
        &:last-child{
            background: rgba(191, 202, 228, 0.23);
        }
    }

    tr{

        &:last-child{
            border-bottom: 1px solid #BFCAE4;
        }
    }
}
.land-comp-table-span-red{
    color: #EC548B;
    font-size: 20px;

    @media (max-width: 767px){
        font-size: 18px;
    }
}
.land-comp-table-span-green{
    color: #23AEB7;
    font-size: 20px;

    @media (max-width: 767px){
        font-size: 18px;
    }
}
.land-comp-3-section{
    background: #FAFCFE;
    padding: 70px 0;
    margin-top: 60px;
}
.land-comp-3{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    padding: 0 24px;
    margin: 0 auto;

    @media (max-width: 767px){
        padding: 0 20px;
        justify-content: center;
        text-align: center;
    }
}
.land-comp-3-left{
    width: 58%;
    max-width: 617px;
    color: #000000;

    @media (max-width: 991px) {
        width: 49%;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
    h2{
        font-weight: 600;
        font-size: 40px;
        max-width: 500px;
        line-height: 1.4;
        margin-bottom: 12px;

        @media (max-width: 991px) {
            font-size: 28px;
            line-height: 1.3;
        }
        @media (max-width: 767px){
            text-align: center;
            max-width: 100%;
        }
    }
    h3{
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 30px;

        @media (max-width: 991px) {
            font-size: 22px;
            margin-bottom: 20px;
        }
        @media (max-width: 767px){
            text-align: center;
            font-size: 20px;
        }
    }
    h4{
        font-weight: 500;
        font-size: 20px;
        line-height: 1.65;
        margin-bottom: 0;

        @media (max-width: 991px) {
            font-size: 17px;
            line-height: 1.5;
        }
        @media (max-width: 767px){
            font-size: 20px;
        }
        span{
            color: #244199;
            font-size: 22px;

            @media (max-width: 991px) {
                font-size: 19px;
            }
            @media (max-width: 767px){
                font-size: 20px;
            }
        }
        strong{
            font-size: 30px;
            font-weight: 600;

            @media (max-width: 991px) {
                font-size: 22px;
            }
            @media (max-width: 767px){
                font-size: 27px;
            }
        }
    }
}
.land-comp-3-right{
    width: 40%;
    max-width: 405px;

    @media (max-width: 991px) {
        width: 45%;
    }
    @media (max-width: 767px) {
        width: 100%;
        order: -1;
        display: flex;
        margin-bottom: 30px;
    }
    img{
        width: 100%;
    }
}
.land-comp-3-buttons{
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-top: 70px;

    @media (max-width: 767px){
        flex-direction: column;
    }
    .btn{
        font-size: 20px;
        padding: 0.64em 1.5em;

        @media (max-width: 991px){
            font-size: 16px;
        }
        @media (max-width: 767px){
            padding: 0.64em .5em;
        }
    }
    .btn-yellow{

        img{
            margin-right: 10px;
            transition: transform .3s;
        }
        &:hover{

            img{
                transform: scale(1.3);
            }
        }
    }
}


.home-features {
    padding: 10vh 0;

    @media (max-width: 991px){
        padding: 60px 0;
    }
    @media (max-width: 767px) {
        padding: 40px 0;
    }
}

.home-features-container {
    width: 100%;

    > .text-center{
        padding: 0 20px;
    }
    .title-h2{
        font-size: 40px;
        line-height: 1.4;
        margin-bottom: 60px;
        padding: 0 20px;

        @media (max-width: 1199px){
            font-size: 26px;
        }
        @media (max-width: 991px){
            font-size: 24px;
        }
        @media (max-width: 767px){
            font-size: 19px;
            margin-bottom: 1em;
        }
    }
    .title-p {
        margin-bottom: 30px;
        font-weight: 500;

        @media (max-width: 767px) {
            margin-bottom: 30px;
            font-size: 16px;
        }
    }
    .btn{
        font-size: 20px;

        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}
.payment-slider{
    margin-bottom: 67px;

    @media (max-width: 991px){
        margin-bottom: 50px;
    }
    @media (max-width: 767px){
        margin-bottom: 30px;
    }
}
.marquee__content {
    padding: 0 14px;

    @media (max-width: 767px){
        padding: 0 10px;
    }
    img{
        height: 35px;
        object-fit: contain;
        max-width: 119px;
        width: auto;
    }
}
.land-comp-5{
    background: #FAFCFE;
    padding: 70px 0 108px;
    position: relative;

    @media (max-width: 767px){
        padding: 20px 0 60px;
    }
}
.land-comp-5-bg-1{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 39.17%;
}
.land-comp-5-bg-2{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 35.4%;
}
.land-comp-5-container{
    max-width: 948px;
    margin: 0 auto;
    padding: 24px;

    > h2{
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 80px;

        @media (max-width: 767px){
            font-size: 20px;
            margin-bottom: 40px;
        }
    }
}
.land-comp-5-form{
    background: #FFFFFF;
    border: 1px solid #E0E3EC;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
    border-radius: 22px;
    padding: 42px;
    position: relative;

    @media (max-width: 767px){
        padding: 30px 20px;
    }
    > img{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 28.174%;
    }
}
.land-range{
    margin-bottom: 35px;

    @media (max-width: 767px){
        margin-bottom: 25px;
    }

    input[type="range"]:focus,
    input[type="range"] {
        -webkit-appearance: none;
        vertical-align: middle;
        width: 100%;
        height: 12px;
        border-radius: 10px;
        background-size: 20% 100%;
        background: #ffffff linear-gradient(#244199, #244199) no-repeat;
        border: 1px solid #E7E9F1;
        margin-bottom: 10px;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #3454B5;
        cursor: ew-resize;
        box-shadow: none;
        transition: background .3s ease-in-out;
    }
    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #3454B5;
        cursor: ew-resize;
        box-shadow: none;
        border: none;
        transition: background .3s ease-in-out;
    }
    input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #3454B5;
        cursor: ew-resize;
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
        transition: background .3s ease-in-out;
    }
    input[type="range"]::-webkit-slider-thumb:hover {
        background: #244199;
    }
    input[type="range"]::-moz-range-thumb:hover {
        background: #244199;
    }
    input[type="range"]::-ms-thumb:hover {
        background: #244199;
    }
    input[type="range"]::-webkit-slider-runnable-track  {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
    input[type="range"]::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }
    input[type="range"]::-ms-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    label{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 19px;

        @media (max-width: 767px){
            font-size: 14px;
        }
    }
    span{
        font-size: 18px;
        color: #244199;
    }
}
.land-comp-range-result{
    text-align: center;
    margin-top: 50px;

    @media (max-width: 767px){
        margin-top: 40px;
    }
    p{
        color: #244199;
        font-size: 18px;
        margin-bottom: 3px;
    }
    h3{
        font-weight: 700;
        font-size: 49px;
        color: #244199;
        margin-bottom: 31px;

        @media (max-width: 767px){
            font-size: 34px;
        }
    }
    .btn{
        font-size: 20px;
        padding: 16px 30px;
        border-radius: 4px;

        @media (max-width: 767px){
            font-size: 16px;
            padding: 10px 30px;
        }
    }
}

.land-comp-6{
    padding: 100px 24px 50px;
    margin: 0 auto;
    max-width: 1008px;

    @media (max-width: 767px){
        padding: 80px 20px;
    }
    h2{
        font-weight: 600;
        font-size: 40px;
        color: #000000;
        margin-bottom: 29px;
        line-height: 1.4;

        @media (max-width: 767px){
            font-size: 26px;
        }
    }
    h3{
        font-weight: 600;
        font-size: 40px;
        color: #244199;
        line-height: 1.4;
        margin-bottom: 0;

        @media (max-width: 767px){
            font-size: 26px;
        }
    }
}

.land-comp-7{
    margin-bottom: 30px;

    .slick-initialized .slick-slide{
        padding: 10px;
    }
}
.land-comp-7-slider{

    img{
        border-radius: 16px;

        @media (max-width: 991px){
            width: 365px;
        }
        @media (max-width: 767px){
            width: 300px;
        }
    }
}

.land-comp-8{
    padding: 75px 24px;
    margin: 0 auto;
    max-width: 1056px;
    color: #000000;

    @media (max-width: 767px){
        padding: 50px 20px 0;
    }
    h2{
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 29px;
        line-height: 1.4;

        @media (max-width: 767px){
            font-size: 25px;
        }
    }
    h3{
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 0;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
}

.land-comp-9{
    margin-bottom: 110px;

    @media (max-width: 767px){
        margin-bottom: 60px;
    }
    > img{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}
.land-comp-9-container{
    max-width: 753px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 43px 24px;
}
.land-comp-9-app{
    gap: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    @media (max-width: 767px){
        margin-bottom: 50px;
    }
}
.land-comp-9-img{

    img{
        width: 100%;
    }
}
.land-comp-10{

}
.land-comp-10-container{
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 24px 72px;

    @media (max-width: 767px){
        padding: 0 20px 50px;
    }
    > h2{
        color: #000000;
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 100px;
        line-height: 1.4;

        @media (max-width: 767px){
            font-size: 26px;
            margin-bottom: 40px;
            line-height: 1.3;
        }
    }
}
.land-comp-10-item{
    width: 32%;
    max-width: 350px;
    padding: 20px 30px;
    margin-bottom: 30px;
    background: #F7F7FA;
    border: 1px solid #EBEBEB;
    border-radius: 4px;

    @media (max-width: 767px){
        width: 100%;
        max-width: none;
        padding: 16px 20px;
        margin-bottom: 20px;
    }
    img{
        margin-bottom: 22px;

        @media (max-width: 767px){
            margin-bottom: 14px;
        }
    }
    h3{
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        margin-bottom: 14px;

        @media (max-width: 767px){
            margin-bottom: 8px;
        }
    }
    p{
        color: #858585;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
    }
}
.land-comp-11{
    position: relative;

    &:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 48.6%;
        background: #F6F8FB;

        @media (max-width: 767px){
            height: 100%;
        }
    }
}
.land-comp-11-container{
    position: relative;
    z-index: 1;
    max-width: 1020px;
    padding: 58px 24px;
    margin: 0 auto 45px;
    color: #000000;
    text-align: center;

    @media (max-width: 767px){
        padding: 50px 20px;
        margin-bottom: 0;
    }
    h2{
        font-weight: 400;
        font-size: 35px;
        line-height: 1.4;
        margin-bottom: 70px;

        @media (max-width: 767px){
            font-size: 22px;
            margin-bottom: 45px;
        }
        span{
            color: #244199;
        }
    }
    .btn{
        font-size: 30px;
        padding: 0.55em 1.2em;

        @media (max-width: 767px){
            font-size: 24px;
        }
    }
}
.land-comp-11-block{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 66px;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: -9px;
    }
    h3{
        font-weight: 600;
        font-size: 40px;
        display: flex;
        align-items: center;
        margin-right: 8px;

        @media (max-width: 991px){
            font-size: 28px;
        }
        @media (max-width: 767px) {
            font-size: 30px;
            flex-direction: column;
            align-items: center;
            margin: 0 0 20px 0;

            img{
                width: 60px;
            }
        }
    }
    h4{
        font-weight: 600;
        font-size: 40px;
        display: flex;

        @media (max-width: 991px){
            font-size: 28px;
        }
        @media (max-width: 767px) {
            font-size: 19px;
        }
        span{
            margin-right: 12px;

            @media (max-width: 767px){
                display: none;
            }
        }
        img{
            margin-top: -9px;
            margin-left: -8px;
            margin-right: -30px;

            @media (max-width: 767px) {
                width: 170px;
                margin: 0 -14px 0 0;
            }
        }
    }
}

.comparison-description-container{
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-left: -15px;
    margin-right: -15px;
    top: 55px;
    padding: 10px 0;
    background-color: #ffffff;
    margin-bottom: 30px;

    @media (max-width: 767px){
        position: static;
        margin-left: -8px;
        margin-right: -8px;
    }
}

.comparison-description {
    padding: 0 15px;
    width: 33.33%;
    flex-grow: 1;
    max-width: 567px;
    min-width: 220px;

    @media (max-width: 767px) {
        width: 230px;
        flex: 0 0 230px;
        padding: 0 8px;
    }
}

.niche-compare-selected {
    font-size: 20px;
    padding-left: 10px
}

.niche-single-head {
    margin: 70px auto 35px;
    padding: 16px 0;
    background-color: #4178c1;
    color: #ffffff;
    text-align: center;

    h1 {
        max-width: 710px;
        margin: 25px auto;
    }
}

.niche-line-separator {
    border-top: 1px solid #fdd128;
    border-bottom: 1px solid #fdd128;
}

.niche-related-header {
    color: #ffa528;
}

.niche-related-link {
    width: fit-content;
}

.niche-related-see-all {
    text-align: end;

    a {
        color: inherit;
    }
}

.niche-container {
    width: 100%;
    max-width: 1096px;
    margin: 30px 0 60px;
    flex-wrap: wrap;

    @media (max-width: 575px) {
        margin: 30px 0;
    }
}

.niche-head {
    padding: 0 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 991px) {
        margin: 0 auto;
        padding: 0;
    }
}

.niche-single-list-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;

    div {
        width: calc(100% / 4);

        @media (max-width: 991px) {
            width: 50%;
        }

        @media (max-width: 550px) {
            width: 100%;
        }

        a {
            width: fit-content;
        }
    }
}

.niche-info-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1096px;
    margin: 30px 0;
    padding: 0;

    div {
        width: calc(50% - 5px);

        @media (max-width: 991px) {
            width: 100%;
        }
    }
}

.niches-compare-page{
    max-width: 1245px;
    margin: 0 auto;
    padding: 130px 20px 0;

    @media (max-width: 1199px){
        padding: 100px 20px 0;
    }
    h1{
        font-size: 25px;
        font-weight: 700;
        margin: 0 0 40px;
    }
    img{
        max-width: 100%;
    }

    .comparison-select{

        @media (max-width: 767px){
            width: 100%;
            flex: 0 0 100%;
            max-width: none;
        }
    }
}

.niches-compare-container{
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
        flex-direction: column;
    }
}
.niches-compare-block{
    width: 48.5%;
    padding-bottom: 30px;

    @media (max-width: 991px){
        width: 100%;
    }

    &:first-child{

        .niches-compare-select{
            z-index: 2;
        }
    }
}
.niches-compare-select{
    margin-bottom: 30px;
    position: sticky;
    top: 55px;
    z-index: 1;
    background-color: #fff;
    padding: 10px 0;

    @media (max-width: 1199px){
        top: 50px;
    }
    .vs__dropdown-toggle{
        min-height: 50px;
    }
}
